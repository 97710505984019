
import {
  computed, defineComponent, nextTick, onMounted, ref,
} from 'vue';
import { CircleCheckFilled, CircleCloseFilled } from '@element-plus/icons-vue';
import CoursesService from '@/services/CoursesService';
import { ICourseListItem } from '@/types/interfaces';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as events from 'events';

dayjs.extend(timezone);
dayjs.extend(utc);

export default defineComponent({
  components: { CircleCheckFilled, CircleCloseFilled },
  props: {
    providerId: { type: Number, required: true },
  },
  setup(props, context) {
    const dataLoading = ref(true);
    const classUpdating = ref(false);
    const courseList = ref([] as ICourseListItem[]);

    function setStatusColor(date: unknown) {
      if (!date) return '';

      const top = dayjs().tz('Europe/Berlin').add(6, 'w');
      const bottom = dayjs().tz('Europe/Berlin');
      const test = dayjs(date as string).tz('Europe/Berlin');

      if (test < bottom) return '#fecaca';
      if (test <= top) return '#fef08a';
      return '#86efac';
    }

    function setOverviewUrl(id: number) {
      // eslint-disable-next-line
      return `${process.env.VUE_APP_PIMCORE_API_URI}/nextdeer/dashboard/${id}/overview?apiKey=${process.env.VUE_APP_PIMCORE_API_SECRET}`;
    }

    function fetchClasses() {
      CoursesService.getCoursesByProviderId(props.providerId).then((response) => {
        courseList.value = response.data;
        dataLoading.value = false;
      });
    }

    function handleClassUpdate(obj: ICourseListItem) {
      classUpdating.value = true;
      const {
        id, discontinued, deeplink, comment,
      } = obj;
      console.log(id, discontinued, deeplink, comment);
      CoursesService.updateCourseData(id, { discontinued, deeplink }).then((response) => {
        console.log(response);
      }).catch((err) => {
        // courseList.value.filter((el) => el.id === id)[0].deeplink = !evt;
      }).finally(() => { classUpdating.value = false; });
    }

    function setRowState({ row }: { row: any }) {
      return row.discontinued ? 'discontinued' : '';
    }

    // handle comment block
    const selectedCourse = ref<undefined|ICourseListItem>(undefined);
    const selectedCourseComment = ref('');
    const dialogVisible = ref(false);

    function openComment(obj: ICourseListItem) {
      selectedCourse.value = obj;
      selectedCourseComment.value = obj.comment;
      dialogVisible.value = true;
    }

    // TODO rewrite to composable or merge with function handleClassUpdate 23/12/22 to exclude code duplicate
    function handleUpdateComment() {
      if (!selectedCourse.value) return;
      classUpdating.value = true;
      const {
        id, discontinued, deeplink,
      } = selectedCourse.value;
      CoursesService.updateCourseData(id, { discontinued, deeplink, comment: selectedCourseComment.value }).then((response) => {
        const idx = courseList.value.findIndex((el) => el.id === response.id);
        if (idx > -1) {
          courseList.value[idx].comment = response.comment;
        }
        dialogVisible.value = false;
      }).catch((err) => {
        // TODO - handle error to be discussed in what way
      }).finally(() => { classUpdating.value = false; });
    }

    function handleCommentClose() {
      dialogVisible.value = false;
    }

    function handleCommentCancel() {
      dialogVisible.value = false;
      nextTick(() => {
        selectedCourse.value = undefined;
      });
    }
    // end of comment block

    onMounted(() => {
      fetchClasses();
    });
    return {
      dataLoading,
      classUpdating,
      courseList,
      dayjs,
      setStatusColor,
      setOverviewUrl,
      handleClassUpdate,
      setRowState,

      dialogVisible,
      selectedCourse,
      selectedCourseComment,
      openComment,
      handleUpdateComment,
      handleCommentClose,
      handleCommentCancel,
    };
  },
});
