
import { defineComponent, onMounted, ref } from 'vue';
// eslint-disable-next-line import/no-cycle
import ProviderService from '@/services/ProviderService';
import AppSpinner from '@/components/UI/AppSpinner.vue';
import AppIconButton from '@/components/AppIconButton.vue';
import { ElLoading } from 'element-plus';
// eslint-disable-next-line import/no-cycle
import CoursesService from '@/services/CoursesService';

export default defineComponent({
  components: { AppIconButton, AppSpinner },
  setup() {
    // eslint-disable-next-line no-undef
    const drafts = ref([]);
    const isDataLoading = ref(true);

    function deleteDraft(cid) {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      CoursesService.deleteDraftCourseProvider(cid).then(() => {
        return ProviderService.fetchProviderCourses().then((response) => {
          if (response) {
            drafts.value = response.data.filter((el) => el.state === 'draft');
          }
        });
      }).finally(() => { loading.close(); });
    }
    onMounted(() => {
      ProviderService.fetchProviderCourses().then((response) => {
        if (response) {
          drafts.value = response.data.filter((el) => el.state === 'draft');
        }
      }).finally(() => {
        isDataLoading.value = false;
      });
    });
    return {
      drafts,
      isDataLoading,
      deleteDraft,
    };
  },
});

