
import {
  computed,
  defineComponent, onBeforeMount, onMounted, reactive, ref, watch, inject,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { FORM_STEP } from '@/types/enums';
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import * as validators from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
// eslint-disable-next-line import/no-cycle
import CoursesService from '@/services/CoursesService';
// eslint-disable-next-line import/no-cycle
import useCourseAssets from '@/composable/CourseAssets';
import CourseFormHeader from '@/modules/Course/components/CourseFormHeader.vue';
import CourseFormStepper from '@/modules/Course/components/CourseFormStepper.vue';
import CourseFormStep1 from '@/modules/Course/components/CourseFormStep1.vue';
import CourseFormStep2 from '@/modules/Course/components/CourseFormStep2.vue';
import CourseFormStep3 from '@/modules/Course/components/CourseFormStep3.vue';
import CourseFormStep4 from '@/modules/Course/components/CourseFormStep4.vue';
import CourseFormControls from '@/modules/Course/components/CourseFormControls.vue';
import FormCreateNote from '@/modules/Course/components/FormCreateNote.vue';
import { useMainStore } from '@/store/mainStore';
import { useCourseManageStore } from '@/store/courseManageStore';
import useAgeMapping from '@/composable/AgeMaping';

export default defineComponent({
  components: {
    FormCreateNote,
    CourseFormStepper,
    CourseFormControls,
    CourseFormHeader,
    CourseFormStep1,
    CourseFormStep2,
    CourseFormStep3,
    CourseFormStep4,
  },
  emits: [
    'on-step-back-initial',
    'on-create-course',
    'sync-participant-questions',
    'sync-faq-questions',
  ],
  props: {
    isCreateMode: { type: Boolean, default: false },
    providerId: { type: Number, default: null },
    providerName: { type: String, default: '' },
    courseName: { type: String, default: '' },
    initialData: { type: Object as () => unknown | any, default: () => ({}) },
    locationOptions: { type: Array as () => unknown, default: () => [] },
    optionsLoading: { type: Boolean, default: false },
    participantQuestionOptions: { type: Array as () => unknown, default: () => [] },
    faqQuestionOptions: { type: Array as () => unknown, default: () => [] },
  },
  setup(props, context) {
    const courseManageStore = useCourseManageStore();
    const targetsNormalized = computed(() => courseManageStore.targetsNormalized);
    const categoryOptions = computed(() => courseManageStore.categoryOptions);
    const levelOptions = computed(() => courseManageStore.levelOptions);
    const highlightOptions = computed(() => courseManageStore.highlightOptions);
    const rhythmOptions = computed(() => courseManageStore.rhythmOptions);

    const { t } = useI18n({ useScope: 'global' });

    const { createI18nMessage } = validators;
    const withI18nMessage = createI18nMessage({ t });
    const required = withI18nMessage(validators.required);

    const {
      filterExtraTargets,
      calculateTargetsOnAge,
      generateTitle,
      courseNameCut,
    } = useAgeMapping(courseManageStore.targets as any, targetsNormalized.value as any);

    const publishSuccess = ref(false);
    const mainStore = useMainStore();
    const step2Ref = ref();
    const step3Ref = ref();
    const route = useRoute();
    const router = useRouter();
    const dataUpdating = ref(false);
    const validationErrors = ref<any[]>([]);
    const formStep = ref(0);
    const formData = reactive({
      name: '',
      short_description: '',
      description: '',
      special: '',
      mode: 'in_person',
      category_id: null,
      related_categories: [],
      target_groups: [], // audience
      age_min: '0.00',
      age_max: '0.00',
      rhythm_id: null as unknown,
      levels: [],
      highlights: [],
      materials: '',
      faqs: [] as any[],
      locations: [],
      postal_code_id: null,
      participant_questions: [] as any[],
      terms: null,
      claims: null,
      filters: {
        983149: false,
        1742437: false,
        1795116: false,
        4121128: false,
        1795117: false,
        4485715: false,
        3027998: false,
        4485712: false,
        885: false,
        732946: false,
        1024839: false,
        3782195: false,
        3782045: false,
        3782121: false,
        3782086: false,
        823073: false,
        5456881: false,
        5172614: false,
        5477037: false,
        5477327: false,
        205462: false,
        37814: false,
        135470: false,
        135471: false,
        30195: false,
        37813: false,
        251928: false,
      },
      primary_image: null,
      assets: [] as any[],
      state: '',
      vat: 19,
      editable: false,
      audience_filters: {
        is_nurfrauen: false,
        is_madchen: false,
        is_nurjungs: false,
      },
      meta: {
        title: '',
        description: '',
      },
    });

    const course = ref<any>({});

    const externalErrors = reactive({});

    const isProvider = computed(() => mainStore?.roles?.includes('ROLE_PROVIDER'));

    function findTreeNode(tree, value) {
      console.log('WEEEEEE', tree);
      if (!tree) return null;
      const stack = [tree[0]];
      while (stack.length) {
        const node = stack.shift();
        if (node?.id === value) return node;
        // eslint-disable-next-line no-unused-expressions
        node?.children && stack.push(...node.children);
      }
      return null;
    }

    function getMembers(members) {
      let children = [];
      return members.map((mem) => {
        const m = { ...mem };
        if (m.children && m.children.length) {
          children = [...children, ...m.children] as unknown as any;
        }
        delete m.children; // this will not affect the original array object
        return m;
      }).concat(children.length ? getMembers(children) : children);
    }

    const computedLocation = computed(() => {
      if (formData.postal_code_id === null) return '';
      const tree = step2Ref.value?.locs;
      // const selectedId = formData.locations[0];
      // const locationObject: any = props.locationOptions.find((el: any) => el.id === selectedId);
      if (!tree) return '';
      const hasChildren = tree.some((obj: any) => Object.prototype.hasOwnProperty.call(obj, 'children'));
      if (hasChildren) {
        // return findTreeNode(tree, formData.postal_code_id)?.name || '';
        return getMembers(tree).find((obj: any) => obj.id === formData.postal_code_id)?.name || '';
      }
      return tree.find((obj: any) => obj.id === formData.postal_code_id)?.name || '';
    });

    const generatedTitle = computed(() => {
      return generateTitle(
        formData.age_min,
        formData.age_max,
        computedLocation.value,
        formData.name,
        formData.mode,
        formData.target_groups,
        targetsNormalized.value,
        formData.audience_filters.is_nurfrauen,
      );
    });

    const {
      courseId,
      assetsLoading,
      courseAssets,
      getCourseAssets,
    } = useCourseAssets(!props.isCreateMode);

    const notGreaterThen = (value) => Number(value) < Number(formData.age_max);
    const notLessThen = (value) => Number(value) > Number(formData.age_min);
    const stageRequired = (value) => value.filter((el) => el.meta?.type === 'stage').length > 0;
    const termsRequired = (value) => Boolean(value) || Boolean(formData.claims);
    const claimsRequired = (value) => Boolean(value) || Boolean(formData.terms);
    const langRequired = (value) => value !== formData.filters['5172614'];
    const classTypeRequired = (value) => value !== formData.filters['1742437'];

    const isPublishValidation = ref(false);
    const isDraft = computed(() => (formData.state === 'draft' || props.isCreateMode));

    const rules = computed(() => {
      const onNextDraftValidation = {
        name: { required },
        filters: {
          983149: { classTypeRequired: helpers.withMessage('Please select class type', classTypeRequired) },
          5456881: { langRequired: helpers.withMessage('Please select class language', langRequired) },
        },
        age_min: { notGreaterThen: helpers.withMessage(t('validations.not_greater_then'), notGreaterThen) },
        age_max: { notLessThen: helpers.withMessage(t('validations.not_less_then'), notLessThen) },
      };

      const onNextActiveValidation = {
        name: { required },
        filters: {
          983149: { classTypeRequired: helpers.withMessage('Please select class type', classTypeRequired) },
          5456881: { langRequired: helpers.withMessage('Please select class language', langRequired) },
        },
        age_min: { notGreaterThen: helpers.withMessage(t('validations.not_greater_then'), notGreaterThen) },
        age_max: { notLessThen: helpers.withMessage(t('validations.not_less_then'), notLessThen) },
        rhythm_id: { required },
        postal_code_id: { required },
      };

      const onPimcoreValidation = {
        name: { required },
        short_description: { required },
        filters: {
          983149: { classTypeRequired: helpers.withMessage('Please select class type', classTypeRequired) },
          5456881: { langRequired: helpers.withMessage('Please select class language', langRequired) },
        },
        mode: { required },
        special: { required },
        category_id: { required },
        age_min: { notGreaterThen: helpers.withMessage(t('validations.not_greater_then'), notGreaterThen) },
        age_max: { notLessThen: helpers.withMessage(t('validations.not_less_then'), notLessThen) },
        rhythm_id: { required },
        levels: { required },
        highlights: { required },
        postal_code_id: { required },
      };

      const onPublishValidation = {
        name: { required },
        short_description: { required },
        filters: {
          983149: { classTypeRequired: helpers.withMessage('Please select class type', classTypeRequired) },
          5456881: { langRequired: helpers.withMessage('Please select class language', langRequired) },
        },
        mode: { required },
        special: { required },
        category_id: { required },
        // target_groups: { required },
        age_min: { notGreaterThen: helpers.withMessage(t('validations.not_greater_then'), notGreaterThen) },
        age_max: { notLessThen: helpers.withMessage(t('validations.not_less_then'), notLessThen) },
        rhythm_id: { required },
        levels: { required },
        highlights: { required },
        // locations: { required },
        postal_code_id: { required },
        terms: { termsRequired: helpers.withMessage(t('validations.terms_required'), termsRequired) },
        claims: { claimsRequired: helpers.withMessage(t('validations.terms_required'), claimsRequired) },
        assets: { stageRequired: helpers.withMessage(t('validations.stage_image_required'), stageRequired) },
        // metaTitle: { required },
        // metaDescription: { required },
        // vat: { required },
      };

      const onNextValidation = !course.value?.editable && !props.isCreateMode ? onNextActiveValidation : onNextDraftValidation;

      // eslint-disable-next-line no-nested-ternary
      return isPublishValidation.value ? formData.editable ? onPublishValidation : onPimcoreValidation : onNextValidation;
    });

    const v$ = useVuelidate(rules, formData);

    // hardcoded defaults constant to use with target audience
    function initAudience() {
      if (props.initialData.audience_filters) {
        // eslint-disable-next-line camelcase
        const { is_nurfrauen, is_madchen, is_nurjungs } = props.initialData.audience_filters;
        // eslint-disable-next-line camelcase
        formData.audience_filters.is_nurfrauen = is_nurfrauen;
        // eslint-disable-next-line camelcase
        formData.audience_filters.is_madchen = is_madchen;
        // eslint-disable-next-line camelcase
        formData.audience_filters.is_nurjungs = is_nurjungs;
      }
    }

    function setInitialData() {
      formData.name = courseNameCut(props.initialData.name);
      formData.short_description = props.initialData?.short_description || '';
      formData.mode = props.initialData?.mode || 'in_person';
      formData.special = props.initialData?.special || '';
      formData.description = props.initialData?.description || '';
      formData.target_groups = filterExtraTargets(props.initialData?.target_groups);
      formData.age_min = props.initialData?.age_min === '0.1' ? '0.10' : (props.initialData?.age_min || '0.00');
      formData.age_max = props.initialData?.age_max === '0.1' ? '0.10' : (props.initialData?.age_max || '0.00');
      formData.category_id = props.initialData?.category?.id || null;
      // ts-lint:disable-next-line @typescript-eslint/camelcase
      // eslint-disable-next-line camelcase
      formData.related_categories = props.initialData?.related_categories?.map((el) => el.id);
      formData.rhythm_id = props.initialData?.rhythm?.id || null;
      formData.levels = props.initialData?.levels?.map((el) => el.id);
      formData.materials = props.initialData?.materials || '';
      formData.faqs = props.initialData?.faqs;
      formData.locations = props.initialData?.locations?.map((el) => el.id);
      formData.postal_code_id = props.initialData?.postal_code?.id || null;
      formData.highlights = props.initialData?.highlights?.map((el) => el.id);
      formData.participant_questions = props.initialData.participant_questions;
      formData.terms = props.initialData?.terms || null;
      formData.claims = props.initialData?.claims || null;
      formData.primary_image = props.initialData?.primary_image || null;
      formData.assets = props.initialData?.assets || [];
      formData.state = props.initialData?.state || 'draft';
      formData.vat = props.initialData?.vat === undefined ? 19 : props.initialData.vat;
      formData.meta = props.initialData?.meta || { title: '', description: '' };
      formData.editable = props.initialData?.editable || false;
      if (!formData.meta?.title) {
        formData.meta.title = props.initialData.name;
      }
      if (props.initialData?.filters) {
        // eslint-disable-next-line max-len
        formData.filters['983149'] = props.initialData.filters['983149'];
        formData.filters['1742437'] = props.initialData.filters['1742437'];
        formData.filters['1795116'] = props.initialData.filters['1795116'];
        formData.filters['4121128'] = props.initialData.filters['4121128'];
        formData.filters['1795117'] = props.initialData.filters['1795117'];
        formData.filters['4485715'] = props.initialData?.filters['4485715'];
        formData.filters['3027998'] = props.initialData?.filters['3027998'];
        formData.filters['4485712'] = props.initialData?.filters['4485712'];
        formData.filters['885'] = props.initialData?.filters['885'];
        formData.filters['732946'] = props.initialData?.filters['732946'];
        formData.filters['1024839'] = props.initialData?.filters['1024839'];
        formData.filters['3782195'] = props.initialData?.filters['3782195'];
        formData.filters['3782045'] = props.initialData?.filters['3782045'];
        formData.filters['3782121'] = props.initialData?.filters['3782121'];
        formData.filters['3782086'] = props.initialData?.filters['3782086'];
        formData.filters['823073'] = props.initialData?.filters['823073'];
        formData.filters['5456881'] = props.initialData?.filters['5456881'];
        formData.filters['5172614'] = props.initialData?.filters['5172614'];
        formData.filters['5477037'] = props.initialData?.filters['5477037'];
        formData.filters['5477327'] = props.initialData?.filters['5477327'];
        formData.filters['205462'] = props.initialData?.filters['205462'];
        formData.filters['37814'] = props.initialData?.filters['37814'];
        formData.filters['135470'] = props.initialData?.filters['135470'];
        formData.filters['135471'] = props.initialData?.filters['135471'];
        formData.filters['30195'] = props.initialData?.filters['30195'];
        formData.filters['37813'] = props.initialData?.filters['37813'];
        formData.filters['251928'] = props.initialData?.filters['251928'];
      }
    }

    function toFirstError(errorName: string|undefined) :void{
      console.warn('ERROR_NAME', errorName);
      setTimeout(() => {
        if (errorName) {
          // eslint-disable-next-line no-nested-ternary
          const el = errorName === 'filters.983149'
            ? document.getElementsByName('courseType')[0]
            : errorName === 'filters.5456881'
              ? document.getElementsByName('courseLanguage')[0]
              : document.getElementsByName(errorName)[0];
          el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }, 200);
    }

    function setStepOnError(attrName: string) {
      console.log('SET_STEP_ON_ERROR', attrName);
      // eslint-disable-next-line max-len
      const s1 = ['name', 'age_min', 'age_max', 'short_description', 'mode', 'special', 'category_id', 'related_categories', 'target_groups', 'rhythm_id', 'levels', 'highlights', 'courseLanguage', 'courseType', 'filters.983149', 'filters.5456881'];
      const s2 = ['locations', 'participant_questions', 'faqs', 'vat', 'postal_code_id'];
      const s3 = ['assets'];
      const s4 = ['terms', 'claims'];
      if (s1.includes(attrName)) {
        formStep.value = 1;
        router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
        toFirstError(attrName);
      } else if (s2.includes(attrName)) {
        formStep.value = 2;
        router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
        toFirstError(attrName);
      } else if (s3.includes(attrName)) {
        formStep.value = 3;
        router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
        toFirstError(attrName);
      } else if (s4.includes(attrName)) {
        formStep.value = 4;
        router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
        toFirstError(attrName);
      }
    }

    const clearExternalErrors = () => Object.keys(externalErrors).forEach((key) => delete externalErrors[key]);

    /** @function externalErrorHandling to handle external errors in form context
     *  @args err object
     */
    async function externalErrorHandling(err) {
      clearExternalErrors();
      const errors = {};
      const arrErr = Object.entries(err.response?.data?.form?.children) as any[];
      for (let i = 0; i < arrErr.length; i += 1) {
        if (arrErr[i][1]?.errors?.length) {
          errors[arrErr[i][0]] = arrErr[i][1]?.errors;
        }
      }

      Object.assign(externalErrors, errors);
      validationErrors.value = err?.response?.data?.errors || err?.errors || [];
      const keys = Object.keys(errors);
      setStepOnError(keys[0]);
    }

    function handleSetStep(v: number) {
      // test
      formStep.value = v;
      router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
    }
    function handleOnBack() {
      if (props.isCreateMode) { // amend to check if step 1, then emit on step back initial, otherwise set form to previous step
        context.emit('on-step-back-initial');
      }
      if (formStep.value > FORM_STEP.FIRST) {
        formStep.value -= 1;
        router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
      }
    }

    function handleUpdate() {
      const cid = route.params?.cid;
      const payload = {
        provider_id: props.providerId,
        ...formData,
      };

      payload.terms = (formData.terms as unknown as any)?.id || null;
      payload.claims = (formData.claims as unknown as any)?.id || null;
      if (typeof formData.primary_image === 'number') {
        payload.primary_image = formData.primary_image;
      } else {
        payload.primary_image = (formData.primary_image as unknown as any)?.id || null;
      }

      const {
        assets: _assets, state: _state, editable: _editable, ...finalPayload
      } = payload;

      finalPayload.name = generatedTitle.value;
      finalPayload.meta.title = `${generatedTitle.value} | Kursfreunde`;
      finalPayload.target_groups = [...formData.target_groups, ...calculateTargetsOnAge(formData.age_min, formData.age_max)] as never[];
      return isProvider.value
        ? CoursesService.updateDraftCourseDataByProvider(cid as string, finalPayload)
        : CoursesService.updateDraftCourseData(cid as string, finalPayload);
    }

    /** @function handleCreateCourse used to create new course draft */
    function handleCreateCourse(payloadData) {
      clearExternalErrors();
      if (mainStore.roles.includes('ROLE_ADMIN')) {
        CoursesService.createNewCourse(payloadData.payload).then((response) => {
          if (response.status === 201) {
            const cid = response.data.id;
            if (payloadData.isNextStep) {
              router.push(`/course/${cid}/edit?step=${FORM_STEP.SECOND}`);
            } else {
              router.push(`/course/${cid}/edit?step=${FORM_STEP.FIRST}`);
            }
          }
        }).catch((err) => {
          externalErrorHandling(err);
          dataUpdating.value = false;
        }).finally(() => {
          dataUpdating.value = false;
        });
      }
      if (mainStore.roles.includes('ROLE_PROVIDER')) {
        CoursesService.createNewCourseByProvider(payloadData.payload).then((response) => {
          if (response.status === 201) {
            // console.log(response.data);
            const cid = response.data.id;
            if (payloadData.isNextStep) {
              router.push(`/provider-course-edit/${cid}?step=${FORM_STEP.SECOND}`);
            } else {
              router.push(`/provider-course-edit/${cid}?step=${FORM_STEP.FIRST}`);
            }
          }
        }).catch((err) => {
          externalErrorHandling(err);
          dataUpdating.value = false;
        }).finally(() => {
          dataUpdating.value = false;
        });
      }
    }

    /** @function initial create course and move to edit */
    async function saveNextCreateMode(isNext = false) {
      const isValid = await v$.value.$validate();
      console.log(v$.value);
      if (!isValid) {
        const el = document.getElementsByName('name')[0];
        el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        el.focus();
        return;
      }
      const { pid } = route.params;

      const {
        // eslint-disable-next-line camelcase
        category_id,
        // eslint-disable-next-line camelcase
        short_description,
        // eslint-disable-next-line camelcase
        related_categories,
        // eslint-disable-next-line camelcase
        target_groups,
        // eslint-disable-next-line camelcase
        rhythm_id,
        // eslint-disable-next-line camelcase
        age_min,
        // eslint-disable-next-line camelcase
        age_max,
        name,
        description,
        mode,
        levels,
        special,
        highlights,
        // eslint-disable-next-line camelcase
        audience_filters,
        filters,
      } = formData;

      const payload = {
        provider_id: mainStore.roles.includes('ROLE_ADMIN') ? Number(pid) : mainStore.userData?.id,
        name: generatedTitle.value,
        short_description,
        description,
        special,
        mode,
        category_id,
        related_categories,
        target_groups,
        levels,
        rhythm_id,
        age_min,
        age_max,
        highlights,
        audience_filters,
        filters,
      };

      const payloadData = { payload, isNextStep: isNext };
      // context.emit('on-create-course', payloadData);
      handleCreateCourse(payloadData);
    }

    function handlingStepOnError() {
      setStepOnError(v$.value.$errors[0]?.$propertyPath);
    }

    function faqsHasError() {
      console.log('ANSWER CANNOT BE EMPTY');
    }

    function handleNext() {
      if (formStep.value < 4) {
        formStep.value += 1;
      }
    }

    const wrongAspectCounter = ref(0);

    const hasWrongAR = computed(() => {
      return step3Ref.value ? Object.values(step3Ref.value?.imagesRef)?.filter((el) => (el !== null) && !(el as any).correctAspectRatio) : [];
    });

    watch(() => hasWrongAR.value, (nv, ov) => {
      if (ov.length > 0 && nv.length === 0) {
        wrongAspectCounter.value = 0;
      }
    });

    async function handleOnNext(goNext = true) {
      console.log('handle on next');
      if (step2Ref.value.noAnswerInArr && formStep.value === FORM_STEP.SECOND) {
        return;
      }
      validationErrors.value = [];
      clearExternalErrors();

      if (!props.isCreateMode) {
        // isPublishValidation.value = false;
        await v$.value.$reset();
        const isValid = await v$.value.$validate();

        if (!isValid) {
          if (isPublishValidation.value) {
            handlingStepOnError();
          } else {
            // toFirstError(v$.value.$errors[0]?.$property);
            setStepOnError(v$.value.$errors[0]?.$property);
          }
          return;
        }

        if (formStep.value < FORM_STEP.FIFTH) {
          dataUpdating.value = true;

          formData.meta.title = `${formData.name} | Kursfreunde`; // Set meta title as course name;

          const materialsConvertedToString = step2Ref.value.materialsConverted.map((el:string) => el.trim()).join('\n').trim();
          formData.materials = materialsConvertedToString;

          if (formStep.value === FORM_STEP.SECOND) {
            const idArr = [...step2Ref.value.questionsIdArray];
            const reqArr = [...step2Ref.value.requiredArray];
            const payload: any[] = [];
            for (let i = 0; i < idArr.length; i += 1) {
              const item = { id: idArr[i], required: reqArr[i] };
              payload.push(item);
            }
            // map here
            formData.participant_questions = payload.filter((el) => el.id !== ''); // step2Ref.value.questionsIdArray.map((el) => el !== null && el);

            const idFQS = [...step2Ref.value.faqIdArray];
            const answFQS = [...step2Ref.value.faqAnswersArray];
            const payloadFQS: any[] = [];
            for (let i = 0; i < idFQS.length; i += 1) {
              const item = { id: idFQS[i], answer: answFQS[i] };
              payloadFQS.push(item);
            }

            payloadFQS.forEach((el) => console.log(el));

            if (payloadFQS.includes((el) => el.answer.length === 0)) {
              faqsHasError();
              return;
            }

            formData.faqs = payloadFQS.filter((el) => el.id !== '' && el.id !== null);
          }

          if (formStep.value === FORM_STEP.THIRD) {
            //
            if (hasWrongAR.value.length && wrongAspectCounter.value === 0) {
              wrongAspectCounter.value = 1;
              dataUpdating.value = false;
              return;
            }
          }
          const pId = step3Ref.value.primaryId;
          // console.log('primary image id', pId);
          if (pId !== null) {
            formData.primary_image = pId;
          } else {
            formData.primary_image = (formData.primary_image as unknown as any)?.id || null;
          }

          handleUpdate().then((res) => {
            if (res && goNext) {
              formStep.value += 1;
              router.replace({ ...router.currentRoute, query: { step: formStep.value.toString() } });
              // -->
            }
          }).catch((err) => {
            externalErrorHandling(err);
            dataUpdating.value = false;
          }).finally(() => {
            dataUpdating.value = false;
          });
        }
      } else {
        await saveNextCreateMode(true);
      }
    }

    async function handleOnDraft() {
      if (!props.isCreateMode) {
        isPublishValidation.value = false;
        await v$.value.$reset();
        const isValid = await v$.value.$validate();

        if (!isValid) return;
        await handleOnNext(false);
        // isPublishValidation.value = false;
        // await v$.value.$reset();
        // const isValid = await v$.value.$validate();
        //
        // if (!isValid) return;
        // dataUpdating.value = true;
        //
        // handleUpdate().catch((err) => {
        //   externalErrorHandling(err);
        //   dataUpdating.value = false;
        // }).finally(() => {
        //   dataUpdating.value = false;
        // });
      } else {
        await saveNextCreateMode();
      }
    }

    /** @function handleOnPublish handle course publishing */
    async function handleOnPublish() {
      isPublishValidation.value = true;
      const { cid } = route.params;
      formData.assets = courseAssets.value;
      await v$.value.$reset();
      const isValid = await v$.value.$validate();

      console.log('HANDLE PUBLISH');
      if (!isValid) {
        handlingStepOnError();
        return;
      }
      dataUpdating.value = true;
      /** Update draft course before activating */
      handleUpdate().then((r) => {
        const payload = {
          provider_id: props.providerId,
          ...formData,
        };

        payload.terms = (formData.terms as unknown as any)?.id || null;
        payload.claims = (formData.claims as unknown as any)?.id || null;

        const {
          assets: _, state: _state, editable: _editable, ...finalPayload
        } = payload;

        finalPayload.name = generatedTitle.value;
        finalPayload.meta.title = `${generatedTitle.value} | Kursfreunde`;
        finalPayload.target_groups = [...formData.target_groups, ...calculateTargetsOnAge(formData.age_min, formData.age_max)] as never[];

        return isProvider.value
          ? CoursesService.validateCourseDataByProvider(cid as string, finalPayload)
          : CoursesService.validateCourseData(cid as string, finalPayload);
      }).then((res) => {
        console.log('VALIDATION RESPONSE', res);
        /** Then attempt activating */
        return isProvider.value
          ? CoursesService.publishCourseByProvider(cid)
          : CoursesService.publishCourse(cid);
      }).then((ar) => {
        console.log('ACTIVATION RESPONSE', ar);
        Object.keys(externalErrors).forEach((key) => delete externalErrors[key]);
        isPublishValidation.value = false;
        formData.state = 'active';
        if (isProvider.value) {
          window.open(process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI, '_self');
        }
        publishSuccess.value = true;
        dataUpdating.value = false;
      })
        .catch((err) => {
          /** Collecting external errors */
          externalErrorHandling(err);
          dataUpdating.value = false;
        })
        .finally(() => { dataUpdating.value = false; });
    }

    /** @function update course after pdf uploaded - set terms or claims */
    function handleUpdateOnUpload(data) {
      formData[data.target] = { id: data.id };
      handleUpdate().then((res) => {
        formData.claims = res.claims || null;
        formData.terms = res.terms || null;
        courseManageStore.assetsList = res.assets || [];
      });
    }

    function handleUpdateOnDelete(target: string) {
      formData[target] = null;
      handleUpdate().then((res) => {
        formData.claims = res.claims || null;
        formData.terms = res.terms || null;
        courseManageStore.assetsList = res.assets || [];
      });
    }

    function addToAssets(obj: any) {
      formData.assets.push(obj);
    }

    onBeforeMount(() => {
      // console.log('ON BEFORE MOUNT - CREATE MODE: ', props.isCreateMode);
      if (!props.isCreateMode) {
        console.log('COURSE MODE');
        setInitialData();
        initAudience();
      } else {
        formData.editable = true;
        initAudience();
      }
    });
    onMounted(() => {
      if (!props.isCreateMode) {
        setInitialData();
      } else {
        formData.editable = true;
        initAudience();
      }
      formStep.value = Number(route.query?.step) || FORM_STEP.FIRST;
      course.value = props.initialData;
    });

    function syncAssets() {
      formData.assets = courseAssets.value;
    }

    const hideStep = computed(() => !formData?.editable);

    const editState = computed(() => {
      const isPimcore = !formData?.editable;
      const isActive = formData.state === 'active';
      const isAdmin = mainStore.roles.includes('ROLE_ADMIN');
      return {
        isPimcore,
        isActive,
        isAdmin,
        isDraft: isDraft.value,
      };
    });
    watch(() => formStep.value, () => {
      if (window.history?.state?.back?.includes('remote-access')) {
        window.history.state.back = process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI;
      }
    });
    return {
      hasWrongAR,
      editState,
      publishSuccess,
      isPublishValidation,
      courseId,
      step2Ref,
      step3Ref,
      dataUpdating,
      FORM_STEP,
      formStep,
      route,
      formData,
      handleOnBack,
      handleOnNext,

      handleOnDraft,
      v$,

      /** assets data used in step 3 and 4 */
      assetsLoading,
      courseAssets,

      handleUpdateOnUpload,
      handleUpdateOnDelete,
      handleOnPublish,
      validationErrors,
      addToAssets,
      /** Used to show/hide publish button and amend text in Save As draft */
      isDraft,
      /** Temporary expose */
      course,
      externalErrors,
      handleNext,
      getCourseAssets,
      syncAssets,

      isProvider,
      hideStep,

      categoryOptions,
      levelOptions,
      highlightOptions,
      rhythmOptions,
      /** Data & func() to handle target groups */
      targetsNormalized,
      generatedTitle,
      computedLocation,
      handleSetStep,
      wrongAspectCounter,
    };
  },
});
