
import { computed, defineComponent, ref } from 'vue';
import CourseForm from '@/modules/Course/components/CourseForm.vue';
// eslint-disable-next-line import/no-cycle
import { useCourseData } from '@/composable/CourseData';

export default defineComponent({
  components: { CourseForm },
  setup() {
    const dataLoading = ref(true);

    const {
      isOptionsLoading,
      providerId,
      providerName,
      initialData,
      locationOptions,
      participantQuestionOptions,
      faqQuestionOptions,
      updateParticipantQuestions,
      updateFAQ,
    } = useCourseData(true);

    const courseName = computed(() => initialData.value.name);

    return {
      dataLoading,
      providerName,
      providerId,
      initialData,
      courseName,

      isOptionsLoading,
      locationOptions,
      participantQuestionOptions,
      faqQuestionOptions,

      updateParticipantQuestions,
      updateFAQ,
    };
  },
});
