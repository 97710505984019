
import {
  defineComponent, computed, ref, onMounted,
} from 'vue';
import CourseForm from '@/modules/Course/components/CourseForm.vue';
// eslint-disable-next-line import/no-cycle
import { useCourseData } from '@/composable/CourseData';
import AppSpinner from '@/components/UI/AppSpinner.vue';

export default defineComponent({
  components: { AppSpinner, CourseForm },
  setup() {
    const dataLoading = ref(true);

    const {
      isOptionsLoading,
      providerId,
      providerName,
      initialData,
      locationOptions,
      participantQuestionOptions,
      faqQuestionOptions,
      updateParticipantQuestions,
      updateFAQ,
    } = useCourseData(true);

    const courseName = computed(() => initialData.value.name);

    onMounted(() => {
      if (window.history?.state?.back?.includes('remote-access')) {
        window.history.state.back = process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI;
      }
    });

    return {
      dataLoading,
      providerName,
      providerId,
      initialData,
      courseName,

      isOptionsLoading,
      locationOptions,
      participantQuestionOptions,
      faqQuestionOptions,

      updateParticipantQuestions,
      updateFAQ,
    };
  },
});
