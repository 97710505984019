import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useMainStore } from '@/store/mainStore';
import { USER_STATUS } from '@/types/enums';
import LoginPage from '@/modules/Auth/view-model/LoginPage.vue';
import CoursesPage from '@/modules/Courses/view-model/CoursesPage.vue';
import DraftsPage from '@/modules/Courses/view-model/DraftsPage.vue';
import LocalStorageService from '@/services/LocalStorageService';
import ProfilesPage from '@/modules/Profile/view-model/ProfilesPage.vue';
import ProfilePage from '@/modules/Profile/view-model/ProfilePage.vue';
import ProfileNew from '@/modules/Profile/view-model/ProfileNew.vue';
import CreateCoursePage from '@/modules/Course/view-model/CreateCoursePage.vue';
import EditCoursePage from '@/modules/Course/view-model/EditCoursePage.vue';
import CourseForm from '@/modules/Course/components/CourseForm.vue';
import RemoteAccess from '@/modules/RemoteAccess/view-model/RemoteAccess.vue';
import ProviderCourses from '@/modules/Provider/view-model/ProviderCourses.vue';
import ProviderCourseNew from '@/modules/Provider/view-model/ProviderCourseNew.vue';
import ProviderCourseEdit from '@/modules/Provider/view-model/ProviderCourseEdit.vue';
// eslint-disable-next-line import/no-cycle
import AuthService from '@/services/AuthServices';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: () => '/courses',
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursesPage,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
    props: (route) => ({ page: route?.query?.page ? parseInt((route.query.page as string), 10) : 1 }),
  },
  {
    path: '/drafts-list',
    name: 'Drafts',
    component: DraftsPage,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
  },
  {
    path: '/form',
    name: 'Form',
    component: CourseForm,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
  },
  {
    path: '/course/:pid/new',
    name: 'CreateNewCourse',
    component: CreateCoursePage,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
  },
  {
    path: '/course/:cid/edit',
    name: 'EditCourse',
    component: EditCoursePage,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
  },
  {
    path: '/providers',
    name: 'ProvidersProfileList',
    component: ProfilesPage,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
    props: (route) => ({ page: route?.query?.page ? parseInt((route.query.page as string), 10) : 1 }),
  },
  {
    path: '/providers/new',
    name: 'ProfileNew',
    component: ProfileNew,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
  },
  {
    path: '/providers/:id',
    name: 'ProviderProfile',
    component: ProfilePage,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_ADMIN] },
  },
  {
    path: '/provider-courses',
    name: 'ProviderCourses',
    component: ProviderCourses,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_PROVIDER] },
  },
  {
    path: '/provider-course-new',
    name: 'ProviderCourseNew',
    component: ProviderCourseNew,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_PROVIDER] },
  },
  {
    path: '/provider-course-edit/:cid',
    name: 'ProviderCourseEdit',
    component: ProviderCourseEdit,
    meta: { requires: [USER_STATUS.LOGGED_IN, USER_STATUS.ROLE_PROVIDER] },
  },
  {
    path: '/remote-access',
    component: RemoteAccess,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => '/courses',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const mainStore = useMainStore();

  function navigateToLogin() {
    const { userStatus, roles } = mainStore;
    if (userStatus === USER_STATUS.LOGGED_IN && roles.includes('ROLE_ADMIN')) {
      next('/courses');
    } else if (userStatus === USER_STATUS.LOGGED_IN && roles.includes('ROLE_PROVIDER')) {
      next('/provider-course-new');
    } else {
      next();
    }
  }

  function navigate() {
    if (to.meta.requires) {
      const requires = to.meta.requires as USER_STATUS[];
      const { userStatus, roles } = mainStore;

      if (requires.includes(userStatus)) {
        if (requires.includes(mainStore.userType)) {
          next();
        } else {
          next('/login');
        }
      } else {
        next('/login');
      }
    } else {
      next();
    }
  }

  if (to.meta.requires) {
    if (LocalStorageService.getAuthToken()) {
      mainStore.userStatus = 1; // temp solution, rewrite to check is token correct
      if (mainStore.userType) {
        navigate();
      } else {
        AuthService.fetchAndStoreUser().then(() => navigate());
      }
    } else {
      next('/login');
    }
  } else if (to.path === '/login') {
    if (LocalStorageService.getAuthToken()) {
      if (mainStore.userStatus) {
        navigateToLogin();
      } else {
        setTimeout(() => {
          mainStore.userStatus = 1;
          navigateToLogin();
        }, 2000);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
