
import {
  computed, defineComponent, onBeforeMount, ref,
} from 'vue';
import ProviderClasses from '@/modules/Courses/components/ProviderClasses.vue';
import { IProviderDashboard } from '@/types/interfaces';
import ProviderService from '@/services/ProviderService';
import AppFormGroupItem from '@/components/AppFormGroupItem.vue';

export default defineComponent({
  components: { ProviderClasses, AppFormGroupItem },
  props: {
    provider: { type: Object as () => IProviderDashboard, default: {} as () => IProviderDashboard },
  },
  emits: ['on-update'],
  setup(props, context) {
    const statistic = ref([]);
    const activeName = ref('');
    const providerLocal = ref({} as IProviderDashboard);

    const providerName = computed(() => {
      return props.provider?.provider_name || `[${props.provider?.provider_id}]`;
    });

    const hasLink = computed(() => props.provider.url !== null && props.provider.url.length !== 0);
    const dialogVisible = ref(false);
    const providerComment = ref<null|string>(null);
    const providerUrl = ref<null|string>(null);

    const commentNotSet = computed(() => props.provider?.comment === '');

    function handleEditPopup() {
      dialogVisible.value = true;
      providerComment.value = props.provider!.comment;
      providerUrl.value = props.provider!.url;
    }

    function handleClose() {
      providerComment.value = props.provider!.comment;
      providerUrl.value = props.provider!.url;
      dialogVisible.value = false;
    }

    function isValidHttpUrl(str: string) {
      const pattern = new RegExp(
        '^((http|https):\\/\\/)' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i',
      );
      return pattern.test(str);
    }

    const urlError = ref('');
    function handleUpdate() {
      const isUrlValid = isValidHttpUrl(providerUrl.value as string);
      if (providerUrl.value !== null && providerUrl.value.length !== 0 && !isUrlValid) {
        urlError.value = 'Please enter valid url';
        return;
      }
      const payload = {
        url: providerUrl.value,
        comment: providerComment.value,
      };

      ProviderService.updateProviderOnDashboard(props.provider.provider_uuid, payload)
        .then((response) => {
          dialogVisible.value = false;
          const obj = { ...props.provider } as IProviderDashboard;
          obj.url = providerUrl.value;
          obj.comment = providerComment.value;
          context.emit('on-update', obj);
        }).catch((e) => {
          console.log('SHOW ERROR', e.response.status);
        });
    }

    function openUrl() {
      if (props.provider.url === null || props.provider.url === '') return;
      window.open(props.provider.url, '_blank');
    }

    function setStatistic(stats: any) {
      statistic.value = stats;
    }

    onBeforeMount(() => {
      providerLocal.value = props.provider;
    });
    return {
      commentNotSet,
      dialogVisible,
      activeName,
      providerName,
      statistic,
      setStatistic,

      hasLink,
      providerComment,
      providerUrl,
      handleEditPopup,
      handleClose,
      handleUpdate,
      openUrl,
      urlError,
    };
  },
});
