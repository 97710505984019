import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c585ced"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "provider-list-item" }
const _hoisted_2 = { class: "action-block" }
const _hoisted_3 = {
  key: 1,
  class: "profile-link profile-link--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Postcard = _resolveComponent("Postcard")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_DocumentAdd = _resolveComponent("DocumentAdd")!
  const _component_provider_course_list = _resolveComponent("provider-course-list")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createBlock(_component_el_collapse, {
    modelValue: _ctx.activeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
    accordion: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse_item, null, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createTextVNode(_toDisplayString(_ctx.provider.name) + " ", 1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_tooltip, {
                class: "box-item",
                effect: "dark",
                content: "Edit profile",
                placement: "top-end"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    class: "profile-link",
                    to: `/providers/${_ctx.provider.id}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { size: 13 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Postcard)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tooltip, {
                class: "box-item",
                effect: "dark",
                content: "Create course",
                placement: "top-end"
              }, {
                default: _withCtx(() => [
                  (_ctx.isActive)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        class: "profile-link",
                        to: `/course/${_ctx.provider.id}/new`
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, { size: 13 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DocumentAdd)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, [
                        _createVNode(_component_el_icon, { size: 13 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DocumentAdd)
                          ]),
                          _: 1
                        })
                      ]))
                ]),
                _: 1
              })
            ])
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.activeName)
            ? (_openBlock(), _createBlock(_component_provider_course_list, {
                key: 0,
                "provider-id": _ctx.provider.id
              }, null, 8, ["provider-id"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}