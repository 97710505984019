
import { defineComponent, ref } from 'vue';
import { Plus } from '@element-plus/icons-vue';
import { blobToBase64 } from '@/helpers/fileConversions';
import CoursesService from '@/services/CoursesService';
import { ElLoading } from 'element-plus';

export default defineComponent({
  emits: ['on-image-to-cropper', 'update-assets'],
  components: { Plus },
  props: {
    courseId: { type: Number, default: null },
    labelText: { type: String, required: true },
    requirements: { type: String, default: '' },
    imageType: { type: String, default: 'stage' }, // 'stage' || 'image'
  },
  setup(props, ctx) {
    const imageUploading = ref(false);
    const mediaInputRef = ref<null|HTMLInputElement>(null);
    const mediaLoadedKey = ref(0);
    const fileTypeError = ref(false);

    function resetInput() {
      mediaLoadedKey.value += 1;
      mediaInputRef.value!.value = ''; // related to input that we know exactly exists
    }

    function triggerFileSelect() {
      if (mediaInputRef.value) {
        mediaInputRef.value.click();
      }
    }

    async function uploadImages(event) {
      imageUploading.value = true;
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });

      const images = Array.from(event.target.files);

      if (!images.length) {
        imageUploading.value = false;
        resetInput();
        loading.close();
        return;
      }

      try {
        const promises = images.map(async (image) => {
          const b64image = await blobToBase64(image) as string;
          const payload = {
            meta: JSON.stringify({
              order: 0,
              width: 0,
              height: 0,
              top: 0,
              left: 0,
              type: props.imageType,
            }),
            course_id: props.courseId,
            file: b64image.replace('data:', '').replace(/^.+,/, ''),
            name: (image as any).name,
          };
          return CoursesService.uploadAsset(payload);
        });

        const results = await Promise.all(promises);
        ctx.emit('update-assets');
        loading.close();
        // console.log('Promises results -> ', results);
      } catch (error) {
        console.log(error);
        loading.close();
      }
      resetInput();
      imageUploading.value = false;
      loading.close();
    }

    async function onMediaInput(event) {
      const file = event.target.files[0];
      const url = await blobToBase64(file) as string;

      if (!file.type.includes('image')) {
        fileTypeError.value = true;
        resetInput();
        return;
      }

      const payload = {
        meta: JSON.stringify({
          order: 0,
          width: 0,
          height: 0,
          top: 0,
          left: 0,
          type: props.imageType,
        }),
        course_id: props.courseId,
        file: url.replace('data:', '').replace(/^.+,/, ''),
        name: file.name,
      };

      imageUploading.value = true;
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });

      try {
        const res = await CoursesService.uploadAsset(payload);
        if (res.status === 201 || res.status === 200) {
          ctx.emit('on-image-to-cropper', res.data);
        }
      } catch (e) {
        imageUploading.value = false;
        loading.close();
      } finally {
        imageUploading.value = false;
        loading.close();
      }
      resetInput();
    }

    return {
      fileTypeError,
      imageUploading,
      mediaInputRef,
      mediaLoadedKey,
      triggerFileSelect,
      onMediaInput,
      uploadImages,
    };
  },
});
