
import {
  computed, defineComponent, ref, onMounted, watch,
} from 'vue';
import { watchOnce } from '@vueuse/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Validation } from '@vuelidate/core';
import AppFormGroup from '@/components/UI/AppFormGroup.vue';
import AppInfoTooltip from '@/components/AppInfoTooltip.vue';
import ageData from '@/helpers/ageData';

export default defineComponent({
  props: {
    name: { type: String, required: true },
    short_description: { type: String, default: '' },
    special: { type: String, default: '' },
    description: { type: String, default: '' },
    mode: { type: String, default: '' },
    category_id: { type: Number, default: null },
    categoryOptions: { type: Array as () => unknown, default: () => [] },
    related_categories: { type: Array as () => unknown, default: () => [] },
    rhythm_id: { type: Number, default: null },
    rhythmOptions: { type: Array as () => unknown, default: () => [] },
    levels: { type: Array as () => unknown, default: () => [] },
    levelOptions: { type: Array as () => unknown, default: () => [] },
    target_groups: { type: Array as () => unknown, default: () => [] },
    targetOptions: { type: Array as () => unknown, default: () => [] },
    highlights: { type: Array as () => unknown, default: () => [] },
    highlightOptions: { type: Array as () => unknown, default: () => [] },
    age_min: { type: String, default: '0' },
    age_max: { type: String, default: '99' },
    isFrauen: { type: Boolean, default: false },
    isMadchen: { type: Boolean, default: false },
    isJungs: { type: Boolean, default: false },
    isFerienkurs: { type: Boolean, default: false },
    isOutdoorkurs: { type: Boolean, default: false },
    isIntensivkurs: { type: Boolean, default: false },
    isEinzelunterricht: { type: Boolean, default: false },
    isGruppenunterricht: { type: Boolean, default: false },
    isFaschingsferien: { type: Boolean, default: false },
    isWinterferien: { type: Boolean, default: false },
    isOsterferien: { type: Boolean, default: false },
    isPfingstferien: { type: Boolean, default: false },
    isSommerferien: { type: Boolean, default: false },
    isHerbstferien: { type: Boolean, default: false },
    isWeihnachtsferien: { type: Boolean, default: false },
    isJga: { type: Boolean, default: false },
    isGeburtstag: { type: Boolean, default: false },
    isUnternehmensevent: { type: Boolean, default: false },
    isNurManner: { type: Boolean, default: false },
    isLanguageEn: { type: Boolean, default: false },
    isLanguageDe: { type: Boolean, default: false },
    isSupportEn: { type: Boolean, default: false },
    isSupportDe: { type: Boolean, default: false },
    optionsLoading: { type: Boolean, default: false },
    vld: { type: Object as () => Validation, default: {} as Validation },
    externalErrors: { type: Object as () => unknown, default: () => ({}) },
    disabled: { type: Boolean, default: false },
  },
  components: {
    AppInfoTooltip, AppFormGroup,
  },
  setup(props, context) {
    const editor = computed(() => ClassicEditor);
    const ageMin = ref('0.01');
    const ageMax = ref('42');
    const editorConfig = {
      toolbar: [
        'heading', '|',
        'bold', 'italic', 'bulletedList', 'numberedList', '|',
        'outdent', 'indent', '|',
        'blockQuote', 'insertTable', 'undo', 'redo',
      ],
    };
    const filteredValue = ref('');
    const ages = computed(() => {
      if (!filteredValue.value.length) return ageData;
      return ageData.filter((el) => el.label.charAt(0) === filteredValue.value);
    });
    function filterAge(q) {
      filteredValue.value = q;
    }

    const filterNodeMethod = (value, data) => {
      console.log('VALUE -> CourseCategoriesInput', value);
      console.log('DATA -> CourseCategories', data);
      return data.label?.toLowerCase().includes(value?.toLowerCase());
    };

    const courseType = computed({
      // Einzelunterricht => 983149
      // Gruppenunterricht => 1742437
      get: () => {
        // eslint-disable-next-line no-nested-ternary
        return !props.isEinzelunterricht && !props.isGruppenunterricht ? '' : props.isEinzelunterricht ? '983149' : '1742437';
      },
      set: (event) => {
        if (event === '983149') {
          context.emit('update:isEinzelunterricht', true);
          context.emit('update:isGruppenunterricht', false);
        } else {
          context.emit('update:isEinzelunterricht', false);
          context.emit('update:isGruppenunterricht', true);
        }
      },
    });

    const courseLanguage = computed({
      // Deutsch tag id: 5456881
      // Englisch tag id: 5172614
      get: () => {
        // eslint-disable-next-line no-nested-ternary
        return !props.isLanguageEn && !props.isLanguageDe ? '' : props.isLanguageEn ? '5172614' : '5456881';
      },
      set: (event) => {
        if (event === '5172614') {
          context.emit('update:isLanguageEn', true);
          context.emit('update:isLanguageDe', false);
          context.emit('update:isSupportEn', false);
        } else {
          context.emit('update:isLanguageEn', false);
          context.emit('update:isLanguageDe', true);
          context.emit('update:isSupportDe', false);
        }
      },
    });

    // function resetFerien() {
    //   context.emit('update:isFaschingsferien', false);
    //   context.emit('update:isWinterferien', false);
    //   context.emit('update:isOsterferien', false);
    //   context.emit('update:isPfingstferien', false);
    //   context.emit('update:isSommerferien', false);
    //   context.emit('update:isHerbstferien', false);
    //   context.emit('update:isWeihnachtsferien', false);
    // }
    // watch(() => props.isFerienkurs, (nv, ov) => {
    //   if (!nv && ov) {
    //     resetFerien();
    //   }
    // });
    return {
      ageMin,
      ageMax,
      editor,
      editorConfig,
      ageData,
      ages,
      filterAge,
      courseType,
      courseLanguage,
      filterNodeMethod,
    };
  },
});
