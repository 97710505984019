import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-541ca210"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle({ height: `${_ctx.height}px` })
    }, [
      _createVNode(_component_cropper, {
        src: _ctx.image,
        ref: "cropperRef",
        "image-restriction": "stencil",
        "stencil-props": _ctx.stencilSettings,
        "default-position": _ctx.defaultPosition,
        "default-size": _ctx.defaultSize
      }, null, 8, ["src", "stencil-props", "default-position", "default-size"])
    ], 4),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        disabled: _ctx.dataUpdating,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-cancel')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('class.button.cancel')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_el_button, {
        loading: _ctx.dataUpdating,
        onClick: _ctx.manageImage
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.assetId ? _ctx.$t('class.button.update_image') : 'Upload image'), 1)
        ]),
        _: 1
      }, 8, ["loading", "onClick"])
    ])
  ]))
}