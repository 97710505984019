
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { useCourseData } from '@/composable/CourseData';
import AppSpinner from '@/components/UI/AppSpinner.vue';
import CourseForm from '@/modules/Course/components/CourseForm.vue';

export default defineComponent({
  components: { CourseForm, AppSpinner },
  setup() {
    const router = useRouter();

    const {
      isOptionsLoading, providerId, providerName, handleCreateCourse,
    } = useCourseData();

    return {
      providerId,
      providerName,
      router,
      handleCreateCourse,
      isOptionsLoading,
    };
  },
});
