import { AxiosRequestConfig } from 'axios';
// eslint-disable-next-line import/no-cycle
import http from '@/plugins/axios';
import api from '@/plugins/apiChained';
// eslint-disable-next-line import/no-cycle
import apiCatalog from '@/plugins/apiCatalog';

export default class ApiService {
  public static get(resource: string, params?: AxiosRequestConfig) {
    return http.get(resource, params);
  }

  public static getChained(resource: string, params?: AxiosRequestConfig) {
    return api.get(resource, params);
  }

  public static post(resource: string, data?: unknown, config?: AxiosRequestConfig) {
    return http.post(resource, data, config);
  }

  public static put(resource: string, data?: unknown) {
    return http.put(resource, data);
  }

  public static patch(resource: string, data?: unknown) {
    return http.patch(resource, data);
  }

  public static delete(resource: string, data?: AxiosRequestConfig) {
    return http.delete(resource, data);
  }

  public static getAndReturnData(resource: string, params?: AxiosRequestConfig) {
    return this.get(resource, params).then((response) => response.data);
  }

  public static getAndReturnChainedData(resource: string, params?: AxiosRequestConfig) {
    return this.getChained(resource, params).then((response) => response.data);
  }

  public static postAndReturnData(resource: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.post(resource, data, config).then((response) => response);
  }

  public static putAndReturnData(resource: string, data?: unknown) {
    return this.put(resource, data).then((response) => response.data);
  }

  public static deleteAndReturnData(resource: string, data?: unknown) {
    return this.delete(resource, { data } as AxiosRequestConfig).then((response) => response.data);
  }

  public static patchAndReturnData(resource: string, data?: unknown) {
    return this.patch(resource, data).then((response) => response.data);
  }

  /** ApiCatalog service */
  public static getCatalogData(resource: string, params?: AxiosRequestConfig) {
    return apiCatalog.get(resource, params);
  }

  public static postCatalogData(resource: string, data?: unknown, config?: AxiosRequestConfig) {
    return apiCatalog.post(resource, data, config);
  }

  public static putCatalogData(resource: string, data?: unknown) {
    return apiCatalog.put(resource, data);
  }

  public static patchCatalogData(resource: string, data?: unknown) {
    return apiCatalog.patch(resource, data);
  }

  public static deleteCatalogData(resource: string, data?: AxiosRequestConfig) {
    return apiCatalog.delete(resource, data);
  }

  public static getAndReturnCatalogData(resource: string, params?: AxiosRequestConfig) {
    return this.getCatalogData(resource, params).then((response) => response.data);
  }

  public static postAndReturnCatalogData(resource: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.postCatalogData(resource, data, config).then((response) => response);
  }

  public static putAndReturnCatalogData(resource: string, data?: unknown) {
    return this.putCatalogData(resource, data).then((response) => response.data);
  }

  public static patchAndReturnCatalogData(resource: string, data?: unknown) {
    return this.patchCatalogData(resource, data).then((response) => response.data);
  }

  public static deleteAndReturnCatalogData(resource: string, data?: unknown) {
    return this.deleteCatalogData(resource, { data } as AxiosRequestConfig).then((response) => response.data);
  }
}
