export default class LocalStorageService {
  private static AUTH_TOKEN = 'authToken';

  private static REFRESH_TOKEN = 'refreshToken';

  private static EXP_TIME = '';

  private static get(name: string) {
    return localStorage.getItem(name);
  }

  private static set(name: string, value: string): void {
    localStorage.setItem(name, value);
  }

  private static remove(name: string): void {
    localStorage.removeItem(name);
  }

  public static getAuthToken(): string {
    return this.get(this.AUTH_TOKEN) || '';
  }

  public static setAuthToken(authToken: string): void {
    this.set(this.AUTH_TOKEN, authToken);
  }

  public static setRefreshToken(refreshToken: string, expTime = 3600): void {
    this.set(this.REFRESH_TOKEN, refreshToken);
    const et: number = Math.floor(Date.now() / 1000) + expTime;
    this.set(this.EXP_TIME, et.toString());
  }

  public static getRefreshToken(): string {
    return this.get(this.REFRESH_TOKEN) || '';
  }

  public static removeAuthToken(): void {
    this.remove(this.AUTH_TOKEN);
  }

  public static removeRefreshToken(): void {
    this.remove(this.REFRESH_TOKEN);
  }
}
