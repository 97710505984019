
import { computed, defineComponent } from 'vue';
import { useMainStore } from '@/store/mainStore';
import { USER_STATUS } from '@/types/enums';
import AppHeader from '@/components/AppHeader.vue';
import AppNavigation from '@/components/AppNavigation.vue';

export default defineComponent({
  components: { AppHeader, AppNavigation },
  setup() {
    const mainStore = useMainStore();

    const showMenu = computed(() => mainStore.userStatus === USER_STATUS.LOGGED_IN);
    return {
      showMenu,
      test: process.env.VUE_APP_NEXTDEER_API_URI,
    };
  },
});
