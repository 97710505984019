
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import CoursesService from '@/services/CoursesService';
import { ICourseListItem, IProviderDashboard } from '@/types/interfaces';
import ProviderItem from '@/modules/Courses/components/ProviderItem.vue';
import ProvidersPageTotal from '@/modules/Courses/components/ProvidersPageTotal.vue';
import { ElLoading } from 'element-plus';

export default defineComponent({
  components: { ProviderItem, ProvidersPageTotal },
  props: {
    page: { type: Number, default: 1 },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const dataLoading = ref(true);
    const activeNames = ref([]);
    const tableData = ref([] as ICourseListItem[]);
    const providersList = ref([] as IProviderDashboard[]);
    const total = ref(0);
    const currentPage = ref(props.page);
    const downloadExelURL = computed(() => {
      const baseUrl = process.env.VUE_APP_PIMCORE_API_URI;
      const apiKey = process.env.VUE_APP_PIMCORE_API_SECRET;
      return `${baseUrl}/nextdeer/dashboard/excel?apiKey=${apiKey}`;
    });

    const stats = computed(() => {
      const sums = providersList.value.map((el) => el.stats);
      return sums.length ? sums.reduce((a, b) => (
        {
          future: a.future + b.future,
          total: a.total + b.total,
          expired: a.expired + b.expired,
          expiring: a.expiring + b.expiring,
          packages: a.packages + b.packages,
          discontinued: a.discontinued + b.discontinued,
        }
      )) : [];
    });

    function getProvidersBySummary(page = currentPage.value) {
      return CoursesService.getDashboardSummary(page).then((response) => {
        providersList.value = response.data;
        total.value = response.pager.total;
        return response;
      });
    }

    onMounted(() => {
      const p = route.query.page || 1;
      getProvidersBySummary(p as unknown as number).finally(() => {
        dataLoading.value = false;
      });
    });

    function updateCurrentPage() {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      getProvidersBySummary(currentPage.value).finally(() => { loading.close(); });
    }

    function changeCurrentPage(page: number) {
      router.push({
        path: router.currentRoute.value.path,
        query: { page },
      });
    }

    watch(() => route.query.page, (n, o) => {
      if (n !== o && n !== null) {
        currentPage.value = n as unknown as number;
        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        });
        getProvidersBySummary().finally(() => { loading.close(); });
      }
    });

    function handleOnUpdate(obj: IProviderDashboard) {
      const idx = providersList.value.findIndex((el) => el.provider_id === obj.provider_id);
      if (idx > -1) {
        providersList.value[idx].url = obj.url;
        providersList.value[idx].comment = obj.comment;
      }
    }
    return {
      dataLoading,
      providersList,
      activeNames,
      tableData,
      total,
      changeCurrentPage,
      updateCurrentPage,
      currentPage,
      stats,
      handleOnUpdate,
      downloadExelURL,
    };
  },
});
