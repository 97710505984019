
import { defineComponent, onMounted, ref } from 'vue';
import { IProviderDashboard } from '@/types/interfaces';
import { useRouter } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import CoursesService from '@/services/CoursesService';
import ProviderListItem from '@/modules/Profile/components/ProviderListItem.vue';
import { ElLoading } from 'element-plus/es';

export default defineComponent({
  components: { ProviderListItem },
  setup() {
    const dataLoading = ref(true);
    const providersList = ref([] as IProviderDashboard[]);
    const total = ref(0);

    const router = useRouter();

    function changeCurrentPage(page: number) {
      const loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      });
      CoursesService.getProviders(page).then((response) => {
        providersList.value = response.data;
        total.value = response.meta.total;
      }).finally(() => { loading.close(); });
    }

    function createNewProfile() {
      router.push('/providers/new');
    }

    onMounted(() => {
      CoursesService.getProviders().then((response) => {
        providersList.value = response.data;
        total.value = response.meta.total;
      }).finally(() => { dataLoading.value = false; });
    });

    return {
      dataLoading,
      providersList,
      total,
      changeCurrentPage,
      createNewProfile,
    };
  },
});
