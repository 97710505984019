import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ba7603a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "page-loading"
}
const _hoisted_2 = {
  key: 1,
  class: "create-course-form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_course_form = _resolveComponent("course-form")!

  return (_ctx.isOptionsLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_spinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_course_form, {
          "provider-name": _ctx.providerName,
          "options-loading": _ctx.isOptionsLoading,
          onOnStepBackInitial: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.push('/providers'))),
          onOnCreateCourse: _ctx.handleCreateCourse,
          "is-create-mode": ""
        }, null, 8, ["provider-name", "options-loading", "onOnCreateCourse"])
      ]))
}