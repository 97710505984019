import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-268259a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "third-step"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "stage-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "no-stage-image"
}
const _hoisted_6 = { class: "no-images" }
const _hoisted_7 = { class: "image-input-wrapper" }
const _hoisted_8 = { class: "images-wrapper" }
const _hoisted_9 = { class: "image-input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_app_info_tooltip = _resolveComponent("app-info-tooltip")!
  const _component_image_item = _resolveComponent("image-item")!
  const _component_app_form_group = _resolveComponent("app-form-group")!
  const _component_image_input = _resolveComponent("image-input")!
  const _component_app_cropper = _resolveComponent("app-cropper")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_asset_delete_dialog = _resolveComponent("asset-delete-dialog")!

  return (_ctx.editState.isPimcore)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_alert, {
          title: "Course created in old system, to update images please contact support",
          type: "warning",
          effect: "dark",
          closable: false,
          style: {"margin-bottom":"24px"}
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('class.upload_pictures_title')), 1),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "For stag image - " + _toDisplayString(_ctx.$t('class.stag_pic_requirements')), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_app_form_group, {
          label: _ctx.$t('class.stage_images'),
          errors: [...(_ctx.vld.assets?.$errors || []), ...(_ctx.externalErrors?.assets || [])],
          required: "",
          simplified: "",
          name: "assets"
        }, {
          helper: _withCtx(() => [
            _createVNode(_component_app_info_tooltip, {
              content: _ctx.$t('class.info_sign.class_image_stage')
            }, null, 8, ["content"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.stageList.length === 0 && !_ctx.assetsLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('class.no_images')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stageList, (item) => {
                return (_openBlock(), _createBlock(_component_image_item, {
                  ref_for: true,
                  ref: (el) => (_ctx.imagesRef[item.id] = el),
                  key: item.id,
                  asset: item,
                  primaryId: _ctx.primaryId,
                  "onUpdate:primaryId": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.primaryId) = $event)),
                  onOnDelete: ($event: any) => (_ctx.handleImageDelete(item.id)),
                  onOnEdit: ($event: any) => (_ctx.handleImageEdit(item, 'stage')),
                  "img-type": "stage",
                  "with-primary": !_ctx.viewOnly
                }, null, 8, ["asset", "primaryId", "onOnDelete", "onOnEdit", "with-primary"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["label", "errors"]),
        _createElementVNode("div", _hoisted_7, [
          (!_ctx.viewOnly)
            ? (_openBlock(), _createBlock(_component_image_input, {
                key: 0,
                "course-id": _ctx.courseId,
                "label-text": _ctx.$t('class.add_additional_stag_pic'),
                onUpdateAssets: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update-assets'))),
                onOnImageToCropper: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addMedia($event, 'stage')))
              }, null, 8, ["course-id", "label-text"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_app_form_group, {
          label: _ctx.$t('class.additional_images'),
          simplified: ""
        }, {
          helper: _withCtx(() => [
            _createVNode(_component_app_info_tooltip, {
              content: _ctx.$t('class.info_sign.class_image_additional')
            }, null, 8, ["content"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (item) => {
                return (_openBlock(), _createBlock(_component_image_item, {
                  key: item.id,
                  asset: item,
                  onOnDelete: ($event: any) => (_ctx.handleImageDelete(item.id)),
                  onOnEdit: ($event: any) => (_ctx.handleImageEdit(item))
                }, null, 8, ["asset", "onOnDelete", "onOnEdit"]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["label"]),
        _createElementVNode("div", _hoisted_9, [
          (!_ctx.viewOnly)
            ? (_openBlock(), _createBlock(_component_image_input, {
                key: 0,
                "label-text": _ctx.$t('class.add_optional_pic'),
                "course-id": _ctx.courseId,
                "image-type": "image",
                onUpdateAssets: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update-assets'))),
                onOnImageToCropper: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleImageEdit($event, 'image')))
              }, null, 8, ["label-text", "course-id"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_el_dialog, {
          modelValue: _ctx.showCropper,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showCropper) = $event)),
          title: _ctx.$t('class.select_crop_area'),
          "close-on-click-modal": false,
          "close-on-press-escape": false,
          width: "80%"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (_openBlock(), _createBlock(_component_app_cropper, {
                height: "300",
                image: _ctx.asset.url,
                "image-name": _ctx.asset.name,
                meta: _ctx.asset.meta,
                "course-id": _ctx.courseId,
                "asset-id": _ctx.asset.assetId,
                "cropper-type": _ctx.cropperType,
                onOnCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCropper = false)),
                onOnSuccess: _ctx.handleOnSuccessUpload,
                key: _ctx.cropperType
              }, null, 8, ["image", "image-name", "meta", "course-id", "asset-id", "cropper-type", "onOnSuccess"]))
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "title"]),
        _createVNode(_component_asset_delete_dialog, {
          "delete-dialog": _ctx.deleteDialog,
          "asset-id": _ctx.assetIdToDelete,
          onClose: _cache[7] || (_cache[7] = ($event: any) => {_ctx.deleteDialog = false; _ctx.assetIdToDelete = null}),
          onOnCancel: _cache[8] || (_cache[8] = ($event: any) => {_ctx.deleteDialog = false; _ctx.assetIdToDelete = null}),
          onHandleAfterDelete: _ctx.handleAfterDelete
        }, null, 8, ["delete-dialog", "asset-id", "onHandleAfterDelete"])
      ]))
}