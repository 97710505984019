
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { useRoute } from 'vue-router';
/* eslint-disable import/no-cycle */
import ProviderService from '@/services/ProviderService';
import ProfileForm from '@/modules/Profile/components/ProfileForm.vue';
import { IProviderProfile } from '@/types/interfaces';
import NotificationService from '@/services/NotificationService';

export default defineComponent({
  components: { ProfileForm },
  setup() {
    const dataLoading = ref(true);
    const route = useRoute();

    const profileData = ref({} as IProviderProfile); // add profile Interface

    const providerName = computed(() => {
      return profileData?.value.name || `[${profileData.value.pm_meta?.id}] ${profileData.value.pm_meta?.key}`;
    });
    function getProfileData() {
      ProviderService.getProfile(route.params.id as string).then((response) => {
        profileData.value = response;
        dataLoading.value = false;
      }).catch((e) => {
        const { title, detail } = e.response.data;
        NotificationService.notifyFailure(title, detail);
      });
    }
    onMounted(() => {
      getProfileData();
    });
    return {
      dataLoading,
      profileData,

      providerName,
    };
  },
});
