
import { computed, defineComponent, ref } from 'vue';
import { useCourseManageStore } from '@/store/courseManageStore';
import { blobToBase64 } from '@/helpers/fileConversions';
import CoursesService from '@/services/CoursesService';
import { IAsset } from '@/types/interfaces';

export default defineComponent({
  props: {
    labelName: { type: String, required: true },
    courseId: { type: Number },
    target: { type: String, required: true },
    source: { type: Object as () => null | unknown, default: () => null },
    disabled: { type: Boolean, default: false },
  },
  emits: ['update-on-upload', 'delete-pdf'],
  setup(props, ctx) {
    const courseManageStore = useCourseManageStore();

    const pdfRef = ref();
    const pdfKey = ref(0);
    const dataUpdating = ref(false);
    const fileTypeError = ref(false);

    function resetInput() {
      pdfKey.value += 1;
      pdfRef.value!.value = ''; // related to input that we know exactly exists
    }

    const uploadedFile = computed(() => {
      return courseManageStore.pdfList.filter((el: IAsset) => el.id === (props.source as any)?.id)[0] || null;
    });
    const fileName = computed(() => {
      if (props.source === null) return '';
      return courseManageStore.pdfList.filter((el: IAsset) => el.id === (props.source as any).id)[0]?.name || '';
    });

    async function uploadPdf(evt, target) {
      fileTypeError.value = false;
      const f = evt.target.files[0];

      if (f.type !== 'application/pdf') {
        fileTypeError.value = true;
        resetInput();
        return;
      }
      dataUpdating.value = true;
      const url = await blobToBase64(f) as string;
      const payload = {
        course_id: props.courseId,
        name: f.name,
        file: url.replace('data:', '').replace(/^.+,/, ''),
      };

      try {
        const res = await CoursesService.uploadAsset(payload);
        if (res.status === 201 || res.status === 200) {
          ctx.emit('update-on-upload', { id: res.data.id, target });
        }
      } catch (e) {
        dataUpdating.value = false;
      } finally {
        dataUpdating.value = false;
        resetInput();
      }
    }

    return {
      pdfRef,
      pdfKey,
      dataUpdating,
      fileTypeError,
      uploadPdf,

      fileName,
      uploadedFile,
    };
  },
});
