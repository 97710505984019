// eslint-disable-next-line no-shadow
export enum USER_STATUS {
  UNKNOWN, LOGGED_IN, ROLE_PROVIDER, ROLE_ADMIN
}

// eslint-disable-next-line no-shadow
export enum USER_TYPE {
  USER = 'USER', STAFF = 'STAFF', ADMIN = 'ADMIN'
}

// eslint-disable-next-line no-shadow
export enum FORM_STEP {
  'NOT_USED', 'FIRST', 'SECOND', 'THIRD', 'FOURTH', 'FIFTH'
}

// eslint-disable-next-line no-shadow
export enum ASSETS_META_TYPE {
  STAGE = 'stage', IMAGE = 'image', TERMS = 'terms', PROTECTION = 'protection'
}
