import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ebf21de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "draft-list-item" }
const _hoisted_2 = { class: "draft-list-item__body" }
const _hoisted_3 = { class: "draft-list-item__provider" }
const _hoisted_4 = { class: "draft-list-item__course-name" }
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = {
  key: 0,
  style: {"color":"#FF3D00","margin-right":"8px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon_button = _resolveComponent("app-icon-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_EditPen = _resolveComponent("EditPen")!
  const _component_View = _resolveComponent("View")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.provider.name), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.item.editable)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "pimcore"))
        : _createCommentVNode("", true),
      _createVNode(_component_el_popconfirm, {
        teleported: false,
        title: "Are you sure?",
        onConfirm: _ctx.deleteDraft
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_app_icon_button, {
            "icon-component": "Delete",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          })
        ]),
        _: 1
      }, 8, ["onConfirm"]),
      _createVNode(_component_el_tooltip, {
        class: "box-item",
        effect: "dark",
        content: _ctx.item.editable ? 'Edit' : 'View',
        placement: "top-end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            class: "profile-link",
            to: `/course/${_ctx.item.id}/edit`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { size: 13 }, {
                default: _withCtx(() => [
                  (_ctx.item.editable)
                    ? (_openBlock(), _createBlock(_component_EditPen, { key: 0 }))
                    : (_openBlock(), _createBlock(_component_View, { key: 1 }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }, 8, ["content"])
    ])
  ]))
}