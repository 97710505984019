import ApiService from '@/services/ApiService';
import { OFFSET_LIMIT, LIMIT_ON_PAGE } from '@/types/const';
import { ICreateNewCourse } from '@/types/interfaces';

// const BASE_URL = process.env.VUE_APP_NEXTDEER_API_URI;
const BASE_URL = process.env.VUE_APP_NEXTDEER_CATALOG_URI;
const BASE_URL_PIMCORE_API = process.env.VUE_APP_PIMCORE_API_URI;

export default class CoursesService {
  public static getCoursesByPage(): Promise<any> {
    const url = 'https://stage.kursfreunde.com/nextdeer/dashboard?limit=100&page=3&order=lastDate.asc';
    return ApiService.getAndReturnData(url);
  }

  public static getCoursesByProviderId(providerId: number): Promise<any> {
    const url = `${BASE_URL_PIMCORE_API}/nextdeer/dashboard?limit=300&pm_provider_id=${providerId}`;
    return ApiService.getAndReturnChainedData(url);
  }

  public static getProviders(page = 1): Promise<any> {
    const offset = (page - 1) * OFFSET_LIMIT;
    const url = `${BASE_URL}/provider?limit=${LIMIT_ON_PAGE}&offset=${offset}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getDashboardSummary(page = 1): Promise<any> {
    // const offset = (page - 1) * OFFSET_LIMIT;
    const url = `${BASE_URL_PIMCORE_API}/nextdeer/dashboard/summary`;
    return ApiService.getAndReturnData(url, { params: { page, limit: LIMIT_ON_PAGE } });
  }

  public static updateCourseData(courseId: number, payload: unknown): Promise<any> {
    const url = `${BASE_URL_PIMCORE_API}/nextdeer/dashboard/${courseId}`;
    return ApiService.patchAndReturnData(url, payload);
  }

  public static updateDraftCourseData(cid: string, payload: unknown): Promise<any> {
    const url = `${BASE_URL}/course/${cid}`;
    return ApiService.putAndReturnCatalogData(url, payload);
  }

  public static updateDraftCourseDataByProvider(cid: string, payload: unknown): Promise<any> {
    const url = `${BASE_URL}/courses/${cid}`;
    return ApiService.putAndReturnCatalogData(url, payload);
  }

  public static validateCourseData(cid: string, payload: unknown): Promise<any> {
    const url = `${BASE_URL}/course/${cid}/validate?new_state=active`;
    return ApiService.putAndReturnCatalogData(url, payload);
  }

  public static validateCourseDataByProvider(cid: string, payload: unknown): Promise<any> {
    const url = `${BASE_URL}/courses/${cid}/validate?new_state=active`;
    return ApiService.putAndReturnCatalogData(url, payload);
  }

  public static createNewCourse(payload: ICreateNewCourse): Promise<any> {
    console.log('CREATE COURSE');
    const url = `${BASE_URL}/course`;
    return ApiService.postAndReturnCatalogData(url, payload);
  }

  public static createNewCourseByProvider(payload: ICreateNewCourse): Promise<any> {
    console.log('CREATE COURSE BY PROVIDER');
    const url = `${BASE_URL}/courses`;
    return ApiService.postAndReturnCatalogData(url, payload);
  }

  public static getCoursesByProvider(): Promise<any> {
    const url = `${BASE_URL}/courses`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static deleteDraftCourse(cid: number): Promise<any> {
    const url = `${BASE_URL}/course/${cid}`;
    return ApiService.deleteAndReturnCatalogData(url);
  }

  public static deleteDraftCourseProvider(cid: number): Promise<any> {
    console.log('DELETE DRAFT COURSE BY PROVIDER');
    const url = `${BASE_URL}/courses/${cid}`;
    return ApiService.deleteAndReturnCatalogData(url);
  }

  public static getCourseData(courseId: string): Promise<any> {
    const url = `${BASE_URL}/course/${courseId}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getCourseDataByProvider(courseId: string): Promise<any> {
    const url = `${BASE_URL}/courses/${courseId}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getCourseDataByProviderByUUID(courseUUID: string): Promise<any> {
    const url = `${BASE_URL}/courses/${courseUUID}/uuid`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getDraftsData(): Promise<any> {
    const url = `${BASE_URL}/course?state=draft`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getLocation(): Promise<any> {
    const url = `${BASE_URL}/location`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getCategory(): Promise<any> {
    const url = `${BASE_URL}/category`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getLevel(): Promise<any> {
    const url = `${BASE_URL}/level`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getRhythm(): Promise<any> {
    const url = `${BASE_URL}/rhythm`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getTargetGroup(): Promise<any> {
    const url = `${BASE_URL}/target_group`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getHighlights(): Promise<any> {
    const url = `${BASE_URL}/highlight`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static getParticipantQuestions(): Promise<any> {
    const url = `${BASE_URL}/participant_question`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static createParticipantQuestion(payload: unknown): Promise<any> {
    const url = `${BASE_URL}/participant_question`;
    return ApiService.postAndReturnCatalogData(url, payload);
  }

  public static getFaqsQuestions(): Promise<any> {
    const url = `${BASE_URL}/faq`;
    return ApiService.getAndReturnData(url);
  }

  public static getProvidersFaqsQuestions(pid): Promise<any> {
    const url = `${BASE_URL}/faq?provider_id=${pid}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static createFAQ(payload: unknown): Promise<any> {
    const url = `${BASE_URL}/faq`;
    return ApiService.postAndReturnCatalogData(url, payload);
  }

  public static getCourseAssets(cid: number): Promise<any> {
    const url = `${BASE_URL}/asset?course_id=${cid}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static uploadAsset(payload: unknown): Promise<any> {
    const url = `${BASE_URL}/asset`;
    return ApiService.postAndReturnCatalogData(url, payload);
  }

  public static updateAsset(payload: unknown, assetId: any): Promise<any> {
    const url = `${BASE_URL}/asset/${assetId}`;
    return ApiService.putAndReturnData(url, payload);
  }

  public static deleteAsset(payload: unknown): Promise<any> {
    const url = `${BASE_URL}/asset/${payload}`;
    return ApiService.deleteAndReturnCatalogData(url);
  }

  public static publishCourse(courseId: any): Promise<any> {
    const url = `${BASE_URL}/course/${courseId}/activate`;
    return ApiService.putAndReturnData(url, { comment: '' });
  }

  public static publishCourseByProvider(courseId: any): Promise<any> {
    const url = `${BASE_URL}/courses/${courseId}/activate`;
    return ApiService.putAndReturnCatalogData(url, { comment: '' });
  }

  public static copyCourse(cid: number): Promise<any> {
    const url = `${BASE_URL}/course/${cid}/copy`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static searchLocationByZip(zipCode: string): Promise<any> {
    const url = `${BASE_URL}/postalcode?code=${zipCode}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static searchLocationById(zipCodeId: string): Promise<any> {
    const url = `${BASE_URL}/postalcode/${zipCodeId}`;
    return ApiService.getAndReturnCatalogData(url);
  }
}
