
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: { type: String, default: '' },
    error: { type: String, default: '' },
    errors: { type: Array as () => unknown, default: () => [] },
    required: { type: Boolean, default: false },
    simplified: { type: Boolean, default: false },
  },
});
