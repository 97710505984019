
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    content: { type: String, default: '' },
  },
  setup() {
    return {
      visible: true,
    };
  },
});
