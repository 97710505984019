import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue';
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from '@/locales/en.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import de from '@/locales/de.json';
import App from './App.vue';
import router from './router';

import 'normalize.css';
import 'element-plus/dist/index.css';

const i18n = createI18n({
  locale: 'de',
  legacy: false,
  messages: {
    en,
    de,
  },
  // something vue-i18n options here ...
});

const app = createApp(App);
// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app
  .use(createPinia())
  .use(router)
  .use(ElementPlus)
  .use(CKEditor)
  .use(i18n)
  .mount('#app');
