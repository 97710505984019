
import { defineComponent, onMounted, ref } from 'vue';
import DraftCoursesListItem from '@/modules/Courses/components/DraftCoursesListItem.vue';
import CoursesService from '@/services/CoursesService';

export default defineComponent({
  components: { DraftCoursesListItem },
  setup() {
    const dataLoading = ref(true);
    const draftsList = ref([]);

    function removeDeletedDraft(cid: number) {
      const idx = draftsList.value.findIndex((el) => (el as any).id === cid);
      draftsList.value.splice(idx, 1);
    }
    onMounted(async () => {
      const { data } = await CoursesService.getDraftsData();
      if (data?.length) {
        draftsList.value = data;
      }
      dataLoading.value = false;
    });
    return {
      dataLoading,
      draftsList,
      removeDeletedDraft,
    };
  },
});
