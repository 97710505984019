import { ref, onBeforeMount, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CoursesService from '@/services/CoursesService';
import ProviderService from '@/services/ProviderService';
import { getTree, getTreeMod } from '@/helpers/nestedSetHandler';
import { FORM_STEP } from '@/types/enums';
// import { getTargets } from '@/helpers/arrayConverstion';
import { useMainStore } from '@/store/mainStore';
import { useCourseManageStore } from '@/store/courseManageStore';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCourseData(isCourseEdit = false) {
  const route = useRoute();
  const router = useRouter();
  const mainStore = useMainStore();
  const courseManagerStore = useCourseManageStore();

  const isOptionsLoading = ref(false);

  const isAdmin = computed(() => mainStore.roles.includes('ROLE_ADMIN'));
  const isProvider = computed(() => mainStore.roles.includes('ROLE_PROVIDER'));

  const providerId = ref<null|number>(null);
  const providerName = ref<undefined|string>(undefined);

  const initialData = ref({}) as unknown as any;

  const locationOptions = ref<any[]>([]);
  const participantQuestionOptions = ref([]);
  const faqQuestionOptions = ref([]);

  function handleCreateCourse(payloadData) {
    CoursesService.createNewCourse(payloadData.payload).then((response) => {
      if (response.status === 201) {
        const cid = response.data.id;
        if (payloadData.isNextStep) {
          router.push(`/course/${cid}/edit?step=${FORM_STEP.SECOND}`);
        } else {
          router.push(`/course/${cid}/edit?step=${FORM_STEP.FIRST}`);
        }
      }
    });
  }

  async function updateParticipantQuestions() {
    try {
      const participantQuestionResponse = await CoursesService.getParticipantQuestions();
      participantQuestionOptions.value = participantQuestionResponse.data;
    } catch (e) {
      console.log('PARTICIPANT QUESTIONS - UPDATE FAILED');
    }
  }

  async function updateFAQ() {
    try {
      const faqQuestionOptionsResponse = await CoursesService.getProvidersFaqsQuestions(providerId.value);
      faqQuestionOptions.value = faqQuestionOptionsResponse.data;
    } catch (e) {
      console.log('FAQs - UPDATE FAILED');
    }
  }

  async function getFirstPageOptions() {
    const categoriesResponse = await CoursesService.getCategory();
    courseManagerStore.categoryOptions = getTree(categoriesResponse.data)[0]?.children;

    const targetsResponse = await CoursesService.getTargetGroup();
    courseManagerStore.targets = targetsResponse.data || [];

    const levelsResponse = await CoursesService.getLevel();
    courseManagerStore.levelOptions = levelsResponse.data?.sort((a, b) => a.name.localeCompare(b.name));

    const rhythmsResponse = await CoursesService.getRhythm();
    courseManagerStore.rhythmOptions = rhythmsResponse.data?.sort((a, b) => a.name.localeCompare(b.name));

    const highlightResponse = await CoursesService.getHighlights();
    courseManagerStore.highlightOptions = highlightResponse.data?.sort((a, b) => a.name.localeCompare(b.name));
  }

  async function getCreateCourse() {
    isOptionsLoading.value = true;
    try {
      const { pid } = route.params;
      providerId.value = Number(pid) || null;

      if (isAdmin.value) {
        const providerResponse = await ProviderService.getProfile(pid as string);
        providerName.value = providerResponse.name;
      }

      if (isProvider.value) {
        providerName.value = mainStore.userData?.name;
      }

      // await getFirstPageOptions();

      isOptionsLoading.value = false;
    } catch (e) {
      console.log('Error in create course', e.code);
      isOptionsLoading.value = false;
    }
  }

  async function getCourseOptions() {
    isOptionsLoading.value = true;
    try {
      const { cid } = route.params;
      if (isAdmin.value) {
        initialData.value = await CoursesService.getCourseData(cid as string);
      }

      if (isProvider.value) {
        initialData.value = await CoursesService.getCourseDataByProvider(cid as string);
      }

      if (isAdmin.value) {
        const providerData = await ProviderService.getProfile(initialData.value.provider.id as string);
        providerName.value = providerData.name;
        providerId.value = providerData.id;
      }

      if (isProvider.value) {
        providerName.value = mainStore.userData?.name;
        providerId.value = mainStore.userData?.id as number;
      }

      await getFirstPageOptions();

      const locationResponse = await CoursesService.getLocation();
      // locationOptions.value = getTargets(locationResponse.data) || [];
      locationOptions.value = locationResponse.data;

      const participantQuestionResponse = await CoursesService.getParticipantQuestions();
      participantQuestionOptions.value = participantQuestionResponse.data;

      const faqQuestionOptionsResponse = await CoursesService.getProvidersFaqsQuestions(providerId.value);
      faqQuestionOptions.value = faqQuestionOptionsResponse.data;

      isOptionsLoading.value = false;
    } catch (e) {
      console.log('ERROR_IN_EDIT_COURSE: ', e.code);
      isOptionsLoading.value = false;
    }
  }

  onBeforeMount(async () => {
    if (isCourseEdit) {
      await getCourseOptions();
      // await getFirstPageOptions();
    } else {
      await getCreateCourse();
      await getFirstPageOptions();
    }
  });

  return {
    isOptionsLoading,
    isProvider,
    isAdmin,

    providerId, // used for create new course
    providerName, // used for create and edit course

    initialData,

    locationOptions,
    participantQuestionOptions,
    faqQuestionOptions,
    updateParticipantQuestions,
    updateFAQ,

    handleCreateCourse,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function test() {
  return null;
}
