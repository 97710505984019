
import { defineComponent } from 'vue';
import DraftCoursesList from '@/modules/Courses/components/DraftCoursesList.vue';

export default defineComponent({
  components: { DraftCoursesList },
  setup() {
    return {};
  },
});
