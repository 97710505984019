import { ElMessage } from 'element-plus';

export default class NotificationService {
  public static notifySuccess(message?: string) {
    ElMessage({
      message: message || 'Success',
      type: 'success',
      showClose: true,
      zIndex: 5000,
    });
  }

  public static notifyFailure(title = 'Error', detail = 'Something very unexpected happened. Our team is working on a solution already.') {
    ElMessage({
      dangerouslyUseHTMLString: true,
      message: `${title}: <b>${detail}<b/>`,
      type: 'error',
      customClass: 'test-message',
      showClose: true,
      zIndex: 5000,
    });
  }
}
