import axios from 'axios';

const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 100;
let PENDING_REQUESTS = 0;
// create new axios instance
const api = axios.create({
  params: {
    t: new Date().getTime(),
  },
});
/**
 * Axios Request Interceptor
 */
api.interceptors.request.use((config) => {
  // eslint-disable-next-line
  config.headers!['X-Api-Key'] = process.env.VUE_APP_PIMCORE_API_SECRET as string;
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        // eslint-disable-next-line no-plusplus
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});
/**
 * Axios Response Interceptor
 */
api.interceptors.response.use((response) => {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
  return Promise.resolve(response);
}, (error) => {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
  return Promise.reject(error);
});

export default api;
