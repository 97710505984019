import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoFilled = _resolveComponent("InfoFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    class: "box-item",
    effect: "dark",
    content: _ctx.content,
    placement: "top",
    "raw-content": "",
    teleported: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_icon, {
        size: "12",
        color: "#79bbff"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InfoFilled)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["content"]))
}