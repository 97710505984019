import ApiService from '@/services/ApiService';
import { IProviderProfile } from '@/types/interfaces';

// const BASE_URL = process.env.VUE_APP_NEXTDEER_API_URI;
const BASE_URL = process.env.VUE_APP_NEXTDEER_CATALOG_URI;
const BASE_URL_PIMCORE_API = process.env.VUE_APP_PIMCORE_API_URI;

export default class ProviderService {
  public static getProfile(providerId: string): Promise<any> {
    const url = `${BASE_URL}/provider/${providerId}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static updateProfile(providerId: string, payload: IProviderProfile): Promise<any> {
    const url = `${BASE_URL}/provider/${providerId}`;
    return ApiService.putAndReturnCatalogData(url, payload);
  }

  public static createProfile(payload: IProviderProfile): Promise<any> {
    const url = `${BASE_URL}/provider`;
    return ApiService.postAndReturnCatalogData(url, payload);
  }

  public static updateProviderOnDashboard(providerUUID: string, payload: unknown): Promise<any> {
    const url = `${BASE_URL_PIMCORE_API}/nextdeer/dashboard/provider/${providerUUID}`;
    return ApiService.patchAndReturnData(url, payload);
  }

  public static getCoursesByProviderId(providerId: number): Promise<any> {
    const url = `${BASE_URL}/course?provider_id=${providerId}`;
    return ApiService.getAndReturnCatalogData(url);
  }

  public static fetchProviderCourses(): Promise<any> {
    const url = `${BASE_URL}/courses`;
    return ApiService.getAndReturnCatalogData(url);
  }
}
