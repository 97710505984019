
import {
  computed,
  defineComponent, onMounted, ref, watch,
} from 'vue';
// import draggable from 'vuedraggable';
import { ASSETS_META_TYPE } from '@/types/enums';
import AppCropper from '@/components/AppCropper/AppCropper.vue';
import ImageItem from '@/modules/Course/components/ImageItem.vue';
import ImageInput from '@/modules/Course/components/ImageInput.vue';
import AssetDeleteDialog from '@/modules/Course/components/AssetDeleteDialog.vue';
import AppFormGroup from '@/components/UI/AppFormGroup.vue';
import { Validation } from '@vuelidate/core';
import { ElLoading } from 'element-plus';
import { IEditState } from '@/types/interfaces';
import AppInfoTooltip from '@/components/AppInfoTooltip.vue';

export default defineComponent({
  components: {
    AppInfoTooltip,
    // draggable,
    AppCropper,
    ImageItem,
    ImageInput,
    AssetDeleteDialog,
    AppFormGroup,
  },
  emits: ['addToAssets', 'update-assets', 'reset-asset-validation'],
  props: {
    courseAssets: { type: Array as () => unknown, default: () => [] },
    primaryImage: { type: Number, default: null },
    courseId: { type: Number, default: null },
    assetsLoading: { type: Boolean },
    vld: { type: Object as () => Validation, default: {} as Validation },
    externalErrors: { type: Object as () => unknown, default: () => ({}) },
    viewOnly: { type: Boolean, default: false },
    editState: { type: Object as () => IEditState, default: {} as IEditState },
  },
  setup(props, ctx) {
    const primaryId = ref<null | number>(null);
    const showCropper = ref(false);
    const deleteDialog = ref(false);
    const assetIdToDelete = ref<null | number>(null);
    const asset = ref({
      name: '',
      // type: '',
      url: '',
      meta: null,
      assetId: undefined,
    });
    const cropperType = ref('stage');
    const imagesRef = ref({});

    const stageList = ref<any[]>([]);
    const imageList = ref<any[]>([]);

    watch(() => props.courseAssets, (nv) => {
      stageList.value = props.courseAssets?.filter((el) => (el as unknown as any).meta?.type === 'stage');
      imageList.value = props.courseAssets?.filter((el) => (el as unknown as any).meta?.type === 'image');
    }, { immediate: true });
    // const stageList = computed(() => props.courseAssets?.filter((el) => (el as unknown as any).meta?.type === 'stage'));
    // const imageList = computed(() => props.courseAssets?.filter((el) => (el as unknown as any).meta?.type === 'image'));

    watch(() => stageList.value.length, (nv, ov) => {
      ctx.emit('reset-asset-validation');
    });
    function resetAsset() {
      asset.value = {
        name: '',
        // type: '',
        url: '',
        meta: null,
        assetId: undefined,
      };
    }

    function handleImageEdit(obj: any, imageType = 'image') {
      resetAsset();
      cropperType.value = imageType;
      asset.value.name = obj.name;
      // asset.value.type = obj.mime;
      asset.value.url = obj.url;
      asset.value.meta = obj.meta;
      asset.value.assetId = obj.id;
      showCropper.value = true;
    }

    function addMedia(obj: any, imageType = 'image') {
      // if (imageType === 'stage') {
      //   stageList.value.push(obj);
      // } else {
      //   imageList.value.push(obj);
      // }
      ctx.emit('addToAssets', obj);
      handleImageEdit(obj, imageType);
    }

    function handleOnSuccessUpload(obj: any) {
      if (obj.type === 'stage') {
        const idx = stageList.value.findIndex((el) => (el as unknown as any).id === obj.data.id);
        if (idx === -1) {
          stageList.value.push(obj.data);
        } else {
          stageList.value[idx] = obj.data;
        }
      } else {
        const idx = imageList.value.findIndex((el) => (el as unknown as any).id === obj.data.id);
        if (idx === -1) {
          imageList.value.push(obj.data);
        } else {
          imageList.value[idx] = obj.data;
        }
      }
      showCropper.value = false;
    }

    function handleImageDelete(imageId: unknown): void {
      assetIdToDelete.value = imageId as number;
      deleteDialog.value = true;
    }

    function handleAfterDelete(imageId: unknown) {
      // TODO move delete handling to CourseForm
      // let idx = stageList.value.findIndex((el) => (el as unknown as any).id === imageId);
      // if (idx > -1) {
      //   stageList.value.splice(idx, 1);
      // }
      //
      // idx = imageList.value.findIndex((el) => (el as unknown as any).id === imageId);
      // if (idx > -1) {
      //   imageList.value.splice(idx, 1);
      // }
      ctx.emit('update-assets');
      deleteDialog.value = false;
      assetIdToDelete.value = null;
    }

    const enabled = ref(true);
    const dragging = ref(false);

    onMounted(() => {
      primaryId.value = props.primaryImage;
    });

    return {
      primaryId,
      stageList,
      imageList,
      asset,
      addMedia,
      handleImageDelete,
      handleImageEdit,
      handleOnSuccessUpload,

      cropperType,
      showCropper,
      enabled,
      dragging,

      assetIdToDelete,
      deleteDialog,
      handleAfterDelete,
      imagesRef,
    };
  },
});
