
import { defineComponent, reactive, ref } from 'vue';
import { email, required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import type { ElForm } from 'element-plus';
import AuthService from '@/services/AuthServices';

export default defineComponent({
  setup() {
    type FormInstance = InstanceType<typeof ElForm>;

    const dataFetching = ref(false);
    const formSize = ref('large');
    const ruleFormRef = ref<FormInstance>();
    const formData = reactive({
      email: '',
      password: '',
    });

    const rules = reactive({
      email: {
        required: helpers.withMessage('Email required', required),
        // email: helpers.withMessage('Please enter correct email', email),
      },
      password: {
        required: helpers.withMessage('Password required', required),
      },
    });

    const v$ = useVuelidate(rules, formData);

    async function submitForm() {
      const result = await v$.value.$validate();
      if (!result) return;

      dataFetching.value = true;

      const credentials = {
        username: formData.email,
        password: formData.password,
        client_id: process.env.VUE_APP_NEXTDEER_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_NEXTDEER_API_CLIENT_SECRET,
        grant_type: 'password',
      };

      AuthService.login(credentials).then(() => AuthService.fetchAndStoreUser()).catch((e) => {
        console.log(e.response.data.message);
      }).finally(() => { dataFetching.value = false; });
    }

    return {
      v$,
      formData,
      formSize,
      ruleFormRef,
      dataFetching,
      submitForm,
    };
  },
});
