<template>
  <el-alert type="info" style="margin-bottom: 24px">
    <div v-if="imageNotice">
      <p style="margin: 0 0 8px 0; font-size: 14px; line-height: 1.5">
        {{ $t('pimcore_warning') }} <a href="mailto:freundschaftsdienst@kursfreunde.com">freundschaftsdienst@kursfreunde.com</a>
      </p>
    </div>
    <div v-else>
      <p style="font-weight: 600; margin: 0 0 8px 0; font-size: 14px">{{ $t('new_class_note_title') }}</p>
      <p style="margin: 0 0 8px 0; font-size: 14px">
        {{ $t('new_class_note_sub') }}
      </p>
      <ul style="padding-left: 24px; margin-bottom: 8px">
        <li style="margin: 0 0 8px 0; font-size: 14px">{{ $t('new_class_note_1') }}</li>
        <li style="margin: 0 0 8px 0; font-size: 14px">{{ $t('new_class_note_2') }}</li>
        <li style="font-size: 14px">{{ $t('new_class_note_3') }}</li>
      </ul>
      <p style="margin: 0 0 8px 0; font-size: 14px">{{ $t('new_class_note_description') }}</p>
    </div>
  </el-alert>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    imageNotice: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>

</style>
