/* eslint-disable no-param-reassign */
import axios, {
  AxiosError, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import LocalStorageService from '@/services/LocalStorageService';
import { useMainStore } from '@/store/mainStore';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import NotificationService from '@/services/NotificationService';
// import { IUserInfo } from '@/types/interfaces';

const http = axios.create({
  params: {
    t: new Date().getTime(),
  },
});

http.interceptors.request.use((request: AxiosRequestConfig) => {
  // eslint-disable-next-line
  // request.headers!.authToken = LocalStorageService.getAuthToken();
  // eslint-disable-next-line
  // request.headers!.language = LocalStorageService.getLanguage();
  // request.headers['UTC-timezone'] = new Date().getTimezoneOffset() / 60;
  request.headers!['X-Api-Key'] = process.env.VUE_APP_PIMCORE_API_SECRET as string;
  return request;
}, (error: AxiosError) => Promise.reject(error));

http.interceptors.response.use((response: AxiosResponse<{ success: boolean; status: number; errorKey: string; message: string }>) => {
  function showNotificationOnError(errorKey: string, message: string) {
    return errorKey !== 'REGISTRATION_INCOMPLETE';
  }

  if (['put', 'delete', 'PUT', 'DELETE'].includes(String(response.config.method)) && response.data.success) {
    // NotificationService.notifySuccess();
  }
  if (!response.data.success && showNotificationOnError(response.data.errorKey, response.data.message)) {
    // NotificationService.notifyFailure();
  }
  return response;
}, (error) => {
  const { status } = error.response;
  if (status === 401) {
    // LocalStorageService.removeAuthToken();
    // const mainStore = useMainStore();
    // mainStore.user = ''; // {} as IUserInfo;
    // mainStore.userStatus = 0;
    // router.push('/login');

    LocalStorageService.removeAuthToken();
    const mainStore = useMainStore();
    const isAdmin = mainStore?.roles?.includes('ROLE_ADMIN');
    mainStore.user = ''; // {} as IUserInfo
    mainStore.userData = null;
    mainStore.roles = [];
    mainStore.userStatus = 0;

    if (!isAdmin) {
      window.location.href = process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI;
    } else {
      router.push('/login');
    }
  } else if (status === 404) {
    NotificationService.notifyFailure();
    router.push('/404');
  } else if (status === 400) {
    NotificationService.notifyFailure();
    // eslint-disable-next-line
    console.log('');
  } else if (status === 422) {
    console.warn('VALIDATION ERROR');
  } else {
    NotificationService.notifyFailure();
    console.log('FAILURE HAPPEN', error.response);
  }
  return Promise.reject(error);
});

export default http;
