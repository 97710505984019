
import { defineComponent, ref } from 'vue';
import CoursesService from '@/services/CoursesService';

export default defineComponent({
  props: {
    deleteDialog: { type: Boolean, default: false },
    assetId: { type: Number as () => null|number, default: null },
  },
  setup(props, context) {
    const dataUpdating = ref(false);

    function handleConfirm() {
      dataUpdating.value = true;

      CoursesService.deleteAsset(props.assetId).then(() => {
        context.emit('handle-after-delete', props.assetId);
      }).finally(() => { dataUpdating.value = false; });
    }
    return {
      dataUpdating,
      handleConfirm,
    };
  },
});
