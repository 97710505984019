import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a76bdce"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "page-loading"
}
const _hoisted_2 = {
  key: 1,
  class: "wrapper"
}
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_EditPen = _resolveComponent("EditPen")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_icon_button = _resolveComponent("app-icon-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!

  return (_ctx.isDataLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_spinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.drafts, (course) => {
          return (_openBlock(), _createElementBlock("div", {
            key: course.id,
            class: "draft-plate"
          }, [
            _createTextVNode(_toDisplayString(course.name) + " ", 1),
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_router_link, {
                class: "profile-link",
                to: `/provider-course-edit/${course.id}`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, { size: 13 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_EditPen)
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["to"]),
              _createVNode(_component_el_popconfirm, {
                teleported: false,
                title: "Are you sure?",
                onConfirm: ($event: any) => (_ctx.deleteDraft(course.id))
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_app_icon_button, {
                    "icon-component": "Delete",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                  })
                ]),
                _: 2
              }, 1032, ["onConfirm"])
            ])
          ]))
        }), 128))
      ]))
}