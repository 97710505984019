
import { computed, defineComponent, ref } from 'vue';
import { useCourseManageStore } from '@/store/courseManageStore';
import { useMainStore } from '@/store/mainStore';
import AssetDeleteDialog from '@/modules/Course/components/AssetDeleteDialog.vue';
import PdfInput from '@/modules/Course/components/PdfInput.vue';
import AppFormGroup from '@/components/UI/AppFormGroup.vue';
import { Validation } from '@vuelidate/core';
import { IEditState } from '@/types/interfaces';
import AppInfoTooltip from '@/components/AppInfoTooltip.vue';

export default defineComponent({
  components: {
    AppInfoTooltip, PdfInput, AssetDeleteDialog, AppFormGroup,
  },
  props: {
    labels: { type: Array as () => unknown, default: () => [] },
    courseId: { type: Number, default: null },
    terms: { type: Object as () => unknown|null, default: () => null },
    claims: { type: Object as () => unknown|null, default: () => null },
    // vat: { type: Number as () => null|number, default: null },
    // metaTitle: { type: String, default: '' },
    // metaDescription: { type: String, default: '' },
    vld: { type: Object as () => Validation, default: {} as Validation },
    externalErrors: { type: Object as () => unknown, default: () => ({}) },
    viewOnly: { type: Boolean, default: false },
    editState: { type: Object as () => IEditState, default: {} as IEditState },
  },
  setup(props, ctx) {
    const deleteDialog = ref(false);
    const assetIdToDelete = ref<null|number>(null);
    const assetTargetToDelete = ref<undefined|string>(undefined);

    const courseManageStore = useCourseManageStore();
    const mainStore = useMainStore();

    const isProvider = computed(() => mainStore.roles.includes(('ROLE_PROVIDER')));

    const conditions = ref({});
    const vatOptions = [
      { name: 'MwSt.', rate: 19, effective_rate: '19' },
      { name: 'MwSt.', rate: 7, effective_rate: '7' },
      { name: 'MwSt.', rate: 0, effective_rate: '0' },
      // { name: 'steuerbefreit', rate: null, effective_rate: '' },
    ];

    function handleAfterDelete(pdfId: unknown) {
      courseManageStore.handleAssetRemove(pdfId);
      ctx.emit('update-on-delete', assetTargetToDelete.value);
      deleteDialog.value = false;
      assetIdToDelete.value = null;
      assetTargetToDelete.value = undefined;
    }

    function deletePdf(data) {
      assetIdToDelete.value = data.id;
      assetTargetToDelete.value = data.target;
      deleteDialog.value = true;
    }
    return {
      conditions,
      vatOptions,
      isProvider,

      deleteDialog,
      assetIdToDelete,
      deletePdf,
      handleAfterDelete,
    };
  },
});

