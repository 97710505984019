import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0598a96e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "app-form-group__real-label"
}
const _hoisted_2 = {
  key: 0,
  class: "validate-errors"
}
const _hoisted_3 = {
  key: 1,
  class: "app-form-group__simplified"
}
const _hoisted_4 = { style: {"display":"flex","width":"100%"} }
const _hoisted_5 = { class: "helper-slot" }
const _hoisted_6 = { style: {"width":"100%"} }
const _hoisted_7 = {
  key: 0,
  class: "validate-errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-form-group", { 'has-error': _ctx.errors.length || _ctx.error.length }])
  }, [
    (!_ctx.simplified)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(["app-form-group__label", { 'app-form-group__label--required': _ctx.required }])
          }, _toDisplayString(_ctx.label), 3),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          (_ctx.errors.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: idx,
                    class: "validate-errors__item"
                  }, _toDisplayString(error?.$message || error), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass(["app-form-group__label", { 'app-form-group__label--required': _ctx.required }])
            }, _toDisplayString(_ctx.label), 3),
            _createElementVNode("span", _hoisted_5, [
              _renderSlot(_ctx.$slots, "helper", {}, undefined, true)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          (_ctx.errors.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: idx,
                    class: "validate-errors__item"
                  }, _toDisplayString(error.$message || error), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
  ], 2))
}