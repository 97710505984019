
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    stats: { type: Object as () => unknown, default: {} as () => unknown },
  },
  setup() {
    return {};
  },
});
