import { ref, onBeforeMount } from 'vue';
import { useCourseManageStore } from '@/store/courseManageStore';
import { useRoute } from 'vue-router';
import CoursesService from '@/services/CoursesService';
import { ElLoading } from 'element-plus';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCourseAssets(isCourseEdit = false) {
  const courseManageStore = useCourseManageStore();
  const route = useRoute();

  const assetsLoading = ref(true);
  const courseId = ref<null|number>(null);
  const courseAssets = ref([]);

  async function getCourseAssets(cid) {
    assetsLoading.value = true;
    const loading = ElLoading.service({
      lock: true,
      background: 'rgba(0, 0, 0, 0.7)',
    });

    try {
      const { data } = await CoursesService.getCourseAssets(Number(cid));
      courseAssets.value = data;
      courseManageStore.assetsList = data;
      assetsLoading.value = false;
      loading.close();
    } catch (e) {
      console.log('ERROR_IN_USE_COURSE_IMAGES: ', e.code);
      assetsLoading.value = false;
      loading.close();
    }
  }

  onBeforeMount(async () => {
    if (isCourseEdit) {
      const { cid } = route.params;
      courseId.value = Number(cid);
      await getCourseAssets(courseId.value);
    }
  });
  return {
    assetsLoading,
    courseAssets,
    courseId,
    getCourseAssets,
  };
}
