import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c5217f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      disabled: _ctx.lockButtons,
      type: "primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('class.button.back')), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    (_ctx.isDraft)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          loading: _ctx.lockButtons,
          type: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-draft-save')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('class.button.draft')), 1)
          ]),
          _: 1
        }, 8, ["loading"]))
      : (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          loading: _ctx.lockButtons,
          type: "success",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-publish')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isDraft ? _ctx.$t('class.button.draft') : _ctx.$t('class.button.save')), 1)
          ]),
          _: 1
        }, 8, ["loading"])),
    (_ctx.formStep < (_ctx.isPimcore ? 2 : 4))
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          loading: _ctx.lockButtons,
          type: "primary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-next')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('class.button.next')), 1)
          ]),
          _: 1
        }, 8, ["loading"]))
      : _createCommentVNode("", true),
    ((_ctx.formStep === 4 && _ctx.isDraft) || (_ctx.formStep === 2 && _ctx.isDraft && _ctx.isPimcore))
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 3,
          loading: _ctx.lockButtons,
          disabled: false,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-publish'))),
          type: "success"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('class.button.publish')), 1)
          ]),
          _: 1
        }, 8, ["loading"]))
      : _createCommentVNode("", true)
  ]))
}