
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isCreateMode: { type: Boolean, default: false },
    providerName: { type: String, default: '' },
    courseName: { type: String, default: '' },
  },
  setup() {
    return {};
  },
});
