
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    error: { type: String as () => any, default: '' },
    label: { type: String, default: '' },
    isRequired: { type: Boolean, default: false },
  },
  setup(props) {
    const errorMessage = computed(() => props.error || '');
    return {
      errorMessage,
    };
  },
});
