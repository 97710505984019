
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from 'vue';
import CoursesService from '@/services/CoursesService';
import { FormRules, FormInstance } from 'element-plus';
import AppInfoTooltip from '@/components/AppInfoTooltip.vue';
import AppFormGroup from '@/components/UI/AppFormGroup.vue';
import { Validation } from '@vuelidate/core';
import { IEditState } from '@/types/interfaces';
import { getTree } from '@/helpers/nestedSetHandler';
import Treeselect from 'vue3-treeselect';
// import the styles
import 'vue3-treeselect/dist/vue3-treeselect.css';

export default defineComponent({
  components: {
    AppInfoTooltip,
    AppFormGroup,
    Treeselect,
  },
  emits: ['sync-participant-questions', 'sync-faq-questions', 'update:vat', 'update:metaTitle', 'update:loc', 'update:metaDescription'],
  props: {
    materials: { type: String, default: '' },
    providerId: { type: Number, default: null },
    locations: { type: Array as () => unknown, default: () => [] },
    locationOptions: { type: Array as () => unknown, default: () => [] },
    participant_questions: { type: Array as () => unknown, default: () => [] },
    faqs: { type: Array as () => unknown, default: () => [] },
    participantQuestionOptions: { type: Array as () => unknown, default: () => [] },
    faqQuestionOptions: { type: Array as () => unknown, default: () => [] },
    vat: { type: Number as () => null|number, default: null },
    loc: { type: Number as () => null|number, default: null },
    metaTitle: { type: String, default: '' },
    metaDescription: { type: String, default: '' },
    vld: { type: Object as () => Validation, default: {} as Validation },
    externalErrors: { type: Object as () => unknown, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    editState: { type: Object as () => IEditState, default: {} as IEditState },
  },
  setup(props, context) {
    const locSelection = ref();
    const vatOptions = [
      { name: 'MwSt.', rate: 19, effective_rate: '19' },
      { name: 'MwSt.', rate: 7, effective_rate: '7' },
      { name: 'MwSt.', rate: 0, effective_rate: '0' },
      // { name: 'steuerbefreit', rate: null, effective_rate: '' },
    ];

    const zipCode = ref('');
    const locs = ref([]);
    const locsLoading = ref(false);
    const vLoc = computed({
      get: () => props.loc,
      set: (v) => context.emit('update:loc', v),
    });

    const searchByZip = () => {
      locs.value = [];
      locsLoading.value = true;
      locSelection.value.clear();
      CoursesService.searchLocationByZip(zipCode.value).then((response) => {
        if (response.data?.length) {
          const data = getTree(response.data);
          locs.value = data[0].children;
        }
      }).finally(() => { locsLoading.value = false; });
    };

    const materialsConverted = ref(['']);
    function removeMaterial(idx) {
      if (materialsConverted.value.length < 2) {
        materialsConverted.value = [''];
      } else {
        materialsConverted.value.splice(idx, 1);
      }
    }
    const noAnswer = ref(false);
    const faqIdArray = ref<any[]>([]);
    const faqAnswersArray = ref<string[]>([]);
    const noAnswerInArr = computed(() => (faqIdArray.value[0] !== null && faqIdArray.value[0] !== '')
      && faqAnswersArray.value.some((item) => item.trim() === ''));

    const questionsIdArray = ref<unknown[]>([]);
    const requiredArray = ref<boolean[]>([]);

    const showFAQRemove = computed(() => faqIdArray.value.length > 1);
    const showPQRemove = computed(() => questionsIdArray.value.length > 1);

    function addFaq(id = null, answer = '') {
      if (faqIdArray.value.length === 1 && faqIdArray.value[0] === null) {
        faqIdArray.value[0] = id;
        faqAnswersArray.value[0] = answer;
      } else {
        faqIdArray.value.push(id);
        faqAnswersArray.value.push(answer);
      }
    }

    function removeFAQ(idx) {
      faqIdArray.value.splice(idx, 1);
      faqAnswersArray.value.splice(idx, 1);
    }

    function addQuestion(id = null, requiredVal = false) {
      questionsIdArray.value.push(id);
      requiredArray.value.push(requiredVal);
    }

    function removePQ(idx) {
      questionsIdArray.value.splice(idx, 1);
      requiredArray.value.splice(idx, 1);
    }

    function handlePQRequired(idx: number) {
      const qid = questionsIdArray.value[idx];
      const questionObj: any = props.participantQuestionOptions.filter((el: any) => el.id === qid)[0];
      requiredArray.value[idx] = questionObj?.required || false;
    }

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const showCreateFAQ = ref(false);
    const creatingFAQ = ref(false);
    const faqFormRef = ref<FormInstance>();
    const faqRules = reactive<FormRules>({
      question: [{ required: true, message: 'Please input question', trigger: 'change' }],
    });

    const formFAQ = reactive({ question: '' });

    function createFAQ() {
      resetForm(faqFormRef.value);
      formFAQ.question = '';
      showCreateFAQ.value = true;
    }

    async function handleCreateFAQ(formEl: FormInstance|undefined) {
      if (!formEl) return;

      const isValid = await formEl.validate((valid) => valid);
      if (!isValid) return;

      creatingFAQ.value = true;
      const payload = { provider_id: props.providerId, ...formFAQ };

      CoursesService.createFAQ(payload).then((r) => {
        if (r) {
          context.emit('sync-faq-questions');
          setTimeout(() => {
            creatingFAQ.value = false;
            showCreateFAQ.value = false;
            addFaq(r.data.id, '');
          }, 1000);
        }
      }).finally(() => {
        setTimeout(() => {
          creatingFAQ.value = false;
          showCreateFAQ.value = false;
        }, 1300);
      });
    }

    function handleFAQanswer(idx) {
      const qid = faqIdArray.value[idx];
      const questionObj: any = props.faqs.filter((el: any) => el.id === qid)[0];
      faqAnswersArray.value[idx] = questionObj?.answer || '';
    }

    // Create Participant question
    const showCreateParticipant = ref(false);
    const creatingParticipantQuestion = ref(false);
    const participantFormRef = ref<FormInstance>();
    const participantRules = reactive<FormRules>({
      name: [{ required: true, message: 'Please input participant question', trigger: 'change' }],
    });

    const formParticipant = reactive({
      name: '',
      type: 'text',
      required: false,
    });

    function createParticipantQuestion() {
      resetForm(participantFormRef.value);
      formParticipant.name = '';
      formParticipant.type = 'text';
      formParticipant.required = false;
      showCreateParticipant.value = true;
    }

    async function handleCreateParticipantQuestion(formEl: FormInstance|undefined) {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          creatingParticipantQuestion.value = true;
          CoursesService.createParticipantQuestion(formParticipant).then((r) => {
            if (r) {
              context.emit('sync-participant-questions');
              setTimeout(() => {
                creatingParticipantQuestion.value = false;
                showCreateParticipant.value = false;
                addQuestion(r.data.id, r.data.required);
              }, 1000);
            }
          }).finally(() => {
            setTimeout(() => {
              creatingParticipantQuestion.value = false;
              showCreateParticipant.value = false;
            }, 1000);
          });
        }
      });
    }

    onMounted(() => {
      if (props.participant_questions.length > 0) {
        questionsIdArray.value = props.participant_questions.map((el) => (el as unknown as any)?.id);
        requiredArray.value = props.participant_questions.map((el) => (el as unknown as any)?.required);
      } else {
        questionsIdArray.value = [null];
        requiredArray.value = [false];
      }
      if (props.faqs?.length) {
        faqIdArray.value = props.faqs.map((el) => (el as unknown as any)?.id);
        faqAnswersArray.value = props.faqs.map((el) => (el as unknown as any)?.answer);
      } else {
        faqIdArray.value = [null];
        faqAnswersArray.value = [''];
      }
      if (props.loc) {
        locsLoading.value = true;
        CoursesService.searchLocationById(props.loc.toString()).then((response) => {
          const data = getTree(response.data);
          console.log('DATA ---> ', data);
          locs.value = data[0].children;
          zipCode.value = (data[0] as any).name;
        }).finally(() => { locsLoading.value = false; });
      }
      materialsConverted.value = props.materials.length ? props.materials.replaceAll('\\n', '\n').split('\n') : [''];
    });
    return {
      locSelection,
      faqIdArray,
      faqAnswersArray,
      questionsIdArray,
      requiredArray,
      addFaq,
      addQuestion,

      showFAQRemove,
      showPQRemove,

      showCreateFAQ,
      creatingFAQ,
      formFAQ,
      createFAQ,
      handleCreateFAQ,
      removeFAQ,
      handleFAQanswer,

      handlePQRequired,
      removePQ,

      faqFormRef,
      faqRules,

      participantFormRef,
      participantRules,

      showCreateParticipant,
      createParticipantQuestion,
      formParticipant,
      creatingParticipantQuestion,
      handleCreateParticipantQuestion,
      noAnswer,
      noAnswerInArr,

      materialsConverted,
      removeMaterial,

      vatOptions,

      zipCode,
      searchByZip,
      locs,
      locsLoading,
      vLoc,
    };
  },
});
