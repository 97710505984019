
import { computed, defineComponent, ref } from 'vue';
import { IProviderProfiles } from '@/types/interfaces';
import ProviderCourseList from '@/modules/Profile/components/ProviderCourseList.vue';

export default defineComponent({
  components: { ProviderCourseList },
  props: {
    provider: {
      type: Object as () => IProviderProfiles,
      required: true,
      // default: {} as () => IProviderDashboard,
    },
  },
  setup(props) {
    const activeName = ref('');
    const isActive = computed(() => props.provider?.state === 'active');
    return {
      activeName,
      isActive,
    };
  },
});
