import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dadb3ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-nav" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_List = _resolveComponent("List")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Monitor = _resolveComponent("Monitor")!
  const _component_Files = _resolveComponent("Files")!
  const _component_Back = _resolveComponent("Back")!
  const _component_Tickets = _resolveComponent("Tickets")!

  return (_ctx.showMenu)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["menu", { 'collapsed': !_ctx.mainStore.isMenuOpen }])
      }, [
        _createElementVNode("nav", _hoisted_1, [
          (_ctx.isAdmin)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "router-link",
                to: "/providers",
                "data-helper": "Provider Profile temp"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_List)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('nav.providers_list')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isAdmin)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "router-link",
                to: "/courses",
                "data-helper": "Kurse"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Monitor)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('nav.dashboard')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isAdmin)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                class: "router-link",
                to: "/drafts-list",
                "data-helper": "Draft Kurse"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Files)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('nav.draft_courses')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isProvider)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                class: "router-link",
                href: _ctx.backToProviderAdminUri
              }, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Back)
                  ]),
                  _: 1
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('nav.back_to_courses')), 1)
              ], 8, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.isProvider)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 4,
                class: "router-link",
                to: "/provider-courses",
                "data-helper": "Kundenkontaktbuch"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Files)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('nav.draft_courses')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isProvider)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 5,
                class: "router-link",
                to: "/provider-course-new",
                "data-helper": "Kundenkontaktbuch"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_Tickets)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('nav.create_new_course')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}