
import { defineComponent, ref } from 'vue';
import ProfileForm from '@/modules/Profile/components/ProfileForm.vue';
import { IProviderData } from '@/types/interfaces';

export default defineComponent({
  components: { ProfileForm },
  setup() {
    const profileForm = ref({
      about_us: '',
      agreement_conditions: 'test',
      commission: 8,
      contract_terms: 'test',
      imprint: 'test',
      invoice_address: {
        city: '',
        company: '',
        country: '',
        email: '',
        first_name: '',
        last_name: '',
        number: '',
        phone_number: '',
        postal_code: '',
        salutation: '',
        state: '',
        street: '',
      },
      milestones: [],
      name: '',
      office_address: {
        city: '',
        company: '',
        country: '',
        email: '',
        first_name: '',
        last_name: '',
        number: '',
        phone_number: '',
        postal_code: '',
        salutation: '',
        state: '',
        street: '',
      },
      organization_unit: 'kursfreunde',
      philosophy: '',
      philosophy_quote: '',
      state: 'review',
    } as IProviderData);
    return {
      profileForm,
    };
  },
});
