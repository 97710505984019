/* eslint-disable no-param-reassign */
import axios, {
  AxiosError, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import LocalStorageService from '@/services/LocalStorageService';
import { useMainStore } from '@/store/mainStore';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import NotificationService from '@/services/NotificationService';
// eslint-disable-next-line import/no-cycle
// import AuthService from '@/services/AuthServices';
// import { IUserInfo } from '@/types/interfaces';

const apiCatalog = axios.create({
  // params: { t: new Date().getTime() },
});

apiCatalog.interceptors.request.use((request: AxiosRequestConfig) => {
  // eslint-disable-next-line
  request.headers!['Api-Service'] = 'CATALOG';
  // eslint-disable-next-line
  request.headers!.Authorization = LocalStorageService.getAuthToken();
  // request.headers!['X-Api-Key'] = process.env.VUE_APP_PIMCORE_API_SECRET as string;
  return request;
}, (error: AxiosError) => Promise.reject(error));

apiCatalog.interceptors.response.use((
  response: AxiosResponse<{ success: boolean; status: number; errorKey: string; message: string }>,
) => {
  function showNotificationOnError(errorKey: string, message: string) {
    return errorKey !== 'REGISTRATION_INCOMPLETE';
  }

  if (['put', 'delete', 'PUT', 'DELETE'].includes(String(response.config.method)) && response.data.success) {
    // NotificationService.notifySuccess();
  }
  if (!response.data.success && showNotificationOnError(response.data.errorKey, response.data.message)) {
    // NotificationService.notifyFailure();
  }
  return response;
}, (error) => {
  const { status } = error.response;
  // const { responseURL } = error.request;
  // const originalRequest = error.config;
  // let retry = false;

  // if (status === 401 && !retry) {
  //   retry = true;
  //   AuthService.refreshToken().then((res) => {
  //     console.log('REQUEST NEW TOKEN WITH REFRESH TOKEN', res);
  //     // 1. set to local storage and to headers
  //     // 2. update refresh token as well
  //     return apiCatalog(originalRequest);
  //   }).catch((err) => {
  //     return Promise.reject(err);
  //   });
  // }

  function resetTokens() {
    LocalStorageService.removeAuthToken();
    LocalStorageService.removeRefreshToken();
  }

  if (status === 401) {
    const mainStore = useMainStore();
    const isAdmin = mainStore?.roles?.includes('ROLE_ADMIN');
    mainStore.user = ''; // {} as IUserInfo
    mainStore.userData = null;
    mainStore.roles = [];
    mainStore.userStatus = 0;

    if (!isAdmin) {
      console.log('PROVIDER LOGOUT');
      resetTokens();
      window.location.href = process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI;
    } else {
      console.log('ADMIN LOGOUT');
      resetTokens();
      router.push('/login');
    }
  } else if (status === 404) {
    NotificationService.notifyFailure();
    router.push('/404');
  } else if (status === 400) {
    NotificationService.notifyFailure();
    // eslint-disable-next-line
    console.log('');
  } else if (status === 422) {
    console.warn('CATALOG API - VALIDATION ERROR');
  } else {
    NotificationService.notifyFailure();
    console.log('FAILURE HAPPEN', error.response);
  }
  return Promise.reject(error);
});

export default apiCatalog;
