export default function useAgeMapping(targetOptions: any[], targetsNormalized: any[]) {
  // function filteredTargets() {
  //   console.log('Fn -> filteredTargets()', targetOptions);
  //   return targetOptions.filter((el) => el.visible);
  // }

  // const targetsNormalized = filteredTargets().map((el) => ({ ...el, value: el.id, label: el.name }));

  function filterExtraTargets(targets) {
    // targets [{id: n}]
    return targets.filter((el) => targetsNormalized.some((item) => item.id === el.id)).map((el) => el.id);
  }

  function calculateTargetsOnAge(ageMin: string, ageMax: string) {
    const res = targetOptions.filter((obj) => {
      const min = obj.age_min_range ? Number(obj.age_min_range) : null;
      const max = obj.age_max_range ? Number(obj.age_max_range) : null;
      if (!min || !max) return false;
      return min <= Number(ageMax) && max >= Number(ageMin);
    });
    const mappedRes = res.map((el) => el.id);
    return [...new Set(mappedRes)];
  }

  function courseNameCut(courseName: string) {
    return courseName.split('|')[0].trim();
  }

  function generateTitle(
    ageMin: string,
    ageMax:string,
    location = '',
    classTitle = '',
    mode = 'in_person',
    extraGroup: number[] = [], // selected groups from extraGroupsList
    extraGroupList: any[] = [], // extra groups list
    isNurFrauen = false,
  ) {
    // <<class title>>| <<min age>> Monat(e) or Jahr(e) - <<max age>> Monat(e) or Jahr(e)|Area of the city
    // If number is 1M or 1J, it should be Monat or Jahr
    // If number is >1, it should be Monate or Jahre behind
    // If both numbers contain Monate or Jahre, it should be only added once at the end

    // Area of the city: separate input field needed
    // Everything right of the yellow including "|" should be autgenerated
    // Everything that is autogenerated should be visible for the user from beginning with a greyed
    // out placeholder (age will be added, area of the city will be added)
    // eslint-disable-next-line no-nested-ternary
    const locationBasedOnMode = mode === 'in_person' ? location : mode === 'online' ? 'Online' : mode === 'video' ? 'Video' : location;
    function ageNormalizer(age: string) {
      if (!age.includes('.')) return age;
      if (age.includes('0.')) {
        return Number(age.replace('0.', '')).toString();
      }
      return age.replace('06', '5');
    }
    const minAgeNormalized = ageNormalizer(ageMin);
    const maxAgeNormalized = ageNormalizer(ageMax);

    const showAgeMinMonth = (ageMin.includes('0.') || ageMin === '0') && !ageMax.includes('0.');
    const showAgeMaxMonth = (ageMin.includes('0.') || ageMin === '0') && ageMax.includes('0.');

    const years = Number(ageMax) > 1 ? 'Jahre' : 'Jahr';

    const minMonthes = Number(minAgeNormalized) > 1 ? 'Monate' : 'Monat';
    const maxMonthes = Number(maxAgeNormalized) > 1 ? 'Monate' : 'Monat';

    if (Number(ageMin) >= 18) {
      let ageMessage = Number(ageMin) < 65 ? 'Erwachsene' : 'Senioren';
      // function testIdPresence(id) {
      //   return [41, 40, 37, 7, 3, 9].includes(id);
      // }
      if (extraGroup.length && [41, 40, 37, 7, 3, 9].includes(extraGroup[0])) {
        const zeroIndexObject = extraGroupList.find((el) => el.id === extraGroup[0]);
        ageMessage = zeroIndexObject.label || '';
        return `${classTitle} | ${ageMessage} | ${locationBasedOnMode}`;
      }
      if (isNurFrauen) {
        ageMessage = 'Nur Frauen';
        return `${classTitle} | ${ageMessage} | ${locationBasedOnMode}`;
      }
      return `${classTitle} | ${ageMessage} | ${locationBasedOnMode}`;
      // return `${classTitle} | ${ageMin} ${years} ${ageMessage} | ${locationBasedOnMode}`;
    }

    // eslint-disable-next-line max-len
    return `${classTitle} | ${minAgeNormalized} ${showAgeMinMonth ? minMonthes : ''} - ${maxAgeNormalized} ${showAgeMaxMonth ? maxMonthes : years} | ${locationBasedOnMode}`;
  }

  return {
    // targetsNormalized,
    filterExtraTargets,
    calculateTargetsOnAge,
    courseNameCut,
    generateTitle,
  };
}
