import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "course-list"
}
const _hoisted_2 = {
  key: 1,
  class: "course-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_DraftCoursesListItem = _resolveComponent("DraftCoursesListItem")!

  return (_ctx.dataLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_skeleton, { animated: "" }, {
          template: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (item) => {
              return _createVNode(_component_el_skeleton_item, {
                variant: "rect",
                style: {"height":"50px","margin-bottom":"2px"},
                key: item
              })
            }), 64))
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.draftsList, (item) => {
          return (_openBlock(), _createBlock(_component_DraftCoursesListItem, {
            onOnAfterDelete: _ctx.removeDeletedDraft,
            key: item.id,
            item: item
          }, null, 8, ["onOnAfterDelete", "item"]))
        }), 128))
      ]))
}