
import { defineComponent, computed, onMounted } from 'vue';
import { useMainStore } from '@/store/mainStore';
// eslint-disable-next-line import/no-cycle
import { useCourseData } from '@/composable/CourseData';
import AppSpinner from '@/components/UI/AppSpinner.vue';
import CourseForm from '@/modules/Course/components/CourseForm.vue';

export default defineComponent({
  components: { CourseForm, AppSpinner },
  setup() {
    const mainStore = useMainStore();
    const providerId = computed(() => mainStore?.userData?.id);
    const { isOptionsLoading, providerName, handleCreateCourse } = useCourseData();

    function routeBackToKF() {
      console.log('bring user back to KF');
    }
    onMounted(() => {
      if (window.history?.state?.back?.includes('remote-access')) {
        window.history.state.back = process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI;
      }
      // console.log('STATE_BACK', window.history.state.back);
    });
    return {
      providerId,
      isOptionsLoading,
      providerName,
      handleCreateCourse,
      routeBackToKF,
    };
  },
});
