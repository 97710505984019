import LocalStorageService from '@/services/LocalStorageService';
import { useMainStore } from '@/store/mainStore';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
// eslint-disable-next-line import/no-cycle
import ApiService from '@/services/ApiService';

export default class AuthService {
  // public static login(credentials: any) {
  //   const mainStore = useMainStore();
  //
  //   return ApiService.postAndReturnData(`${process.env.VUE_APP_NEXTDEER_IDENTITY_URI}/oauth/token`, credentials)
  //     .then((response) => {
  //       // console.log(response);
  //       if (response.status === 200) {
  //         LocalStorageService.setAuthToken(`${response.data.token_type} ${response.data.access_token}`);
  //         mainStore.userStatus = 1;
  //         router.push('/courses');
  //         return response;
  //       }
  //       LocalStorageService.removeAuthToken();
  //       return response;
  //     });
  // }

  public static login(credentials: any) {
    const mainStore = useMainStore();

    return ApiService.postAndReturnCatalogData(`${process.env.VUE_APP_NEXTDEER_CATALOG_URI}/token`, credentials)
      .then((response) => {
        if (response.status === 200) {
          LocalStorageService.setAuthToken(`${response.data.token_type} ${response.data.access_token}`);
          mainStore.userStatus = 1;
          router.push('/courses');
          return response;
        }
        LocalStorageService.removeAuthToken();
        return response;
      });
  }

  public static refreshToken() {
    const payload = {
      client_id: process.env.VUE_APP_NEXTDEER_API_CLIENT_ID,
      refresh_token: LocalStorageService.getRefreshToken(),
      scope: '',
      client_secret: process.env.VUE_APP_NEXTDEER_API_CLIENT_SECRET,
      grant_type: 'refresh_token',
    };
    return ApiService.postAndReturnCatalogData(`${process.env.VUE_APP_NEXTDEER_CATALOG_URI}/token`, payload);
  }

  public static loginByToken(token: string, refreshToken: string) {
    LocalStorageService.setAuthToken(`Bearer ${token}`);
    LocalStorageService.setRefreshToken(refreshToken, 3600);

    return ApiService.getAndReturnCatalogData(`${process.env.VUE_APP_NEXTDEER_CATALOG_URI}/bootstrap`);
    //   .then((response) => {
    //   if (response) {
    //     const mainStore = useMainStore();
    //     mainStore.userData = response.provider;
    //     mainStore.roles = response.roles;
    //     router.push('/provider-course-new');
    //   }
    // });
  }

  public static fetchAndStoreUser() {
    return ApiService.getAndReturnCatalogData(`${process.env.VUE_APP_NEXTDEER_CATALOG_URI}/bootstrap`).then((response) => {
      if (response) {
        const mainStore = useMainStore();
        mainStore.userData = response.provider;
        mainStore.roles = response.roles;
      }
      return response;
    });
  }
}
