import nestedSetContextFactory from 'nested-set';

export function getTreeMod(nested) {
  const tree = nested.shift();
  tree.children = [];
  let prev = tree;
  const parents = [];
  parents.push(tree);

  let item;
  // eslint-disable-next-line no-cond-assign
  while (item = nested.shift()) {
    item.children = [];

    if ((item.lft > prev.lft) && (item.lft < prev.rgt)) {
      parents.push(prev);
      prev.children.push(item);
    } else {
      let lastParent = parents[parents.length - 1];
      if ((item.lft > lastParent.lft) && (item.lft < lastParent.rgt)) {
        lastParent.children.push(item);
      } else {
        do {
          parents.pop();
          if (parents.length === 0) {
            console.log('error in structure');
            return tree;
          }
          lastParent = parents[parents.length - 1];
        } while (!((item.lft > lastParent.lft) && (item.lft < lastParent.rgt)));
        lastParent.children.push(item);
      }
    }
    prev = item;
  }

  // eslint-disable-next-line consistent-return
  return tree;
}

export function getTree(arr) {
  const normalizedArr = arr.map((el) => ({ ...el, value: el.id, label: el.name }));
  const nestedSetContext = nestedSetContextFactory();
  return nestedSetContext.nest(normalizedArr);
}
