import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af06c4e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-stepper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      "model-value": _ctx.formStep,
      onTabClick: _ctx.setStep
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('course_edit_nav.general_info'),
          name: 1,
          disabled: _ctx.lockButtons
        }, null, 8, ["label", "disabled"]),
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('course_edit_nav.loc_and_faq'),
          name: 2,
          disabled: _ctx.lockButtons
        }, null, 8, ["label", "disabled"]),
        (!_ctx.isPimcore)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: _ctx.$t('course_edit_nav.images'),
              name: 3,
              disabled: _ctx.lockButtons
            }, null, 8, ["label", "disabled"]))
          : _createCommentVNode("", true),
        (!_ctx.isPimcore)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 1,
              label: _ctx.$t('course_edit_nav.legal_data'),
              name: 4,
              disabled: _ctx.lockButtons
            }, null, 8, ["label", "disabled"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["model-value", "onTabClick"])
  ]))
}