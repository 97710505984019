interface IAge {
  group: string;
  groupId: string;
  value: string;
  label: string;
}

function setAgesArray(start: number, stop: number, letter: string, category: string, isAdult = false) {
  const arr: IAge[] = [];
  for (let i = start; i < stop; i += 1) {
    const obj = {
      group: category,
      groupId: '?',
      // eslint-disable-next-line no-nested-ternary
      value: isAdult ? `${i}` : (i < 10 ? `0.0${i}` : `0.${i}`),
      label: `${i}${letter}`,
    };
    arr.push(obj);
  }
  return arr;
}
const babyAges: IAge[] = setAgesArray(0, 12, 'M', 'Baby');

const babyAges2: IAge[] = [
  {
    group: 'Baby',
    groupId: '?',
    value: '1',
    label: '1J',
  },
  {
    group: 'Baby',
    groupId: '?',
    value: '1.06',
    label: '1.5J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '2',
    label: '2J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '2.06',
    label: '2.5J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '3',
    label: '3J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '3.06',
    label: '3,5J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '4',
    label: '4J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '4.06',
    label: '4.5J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '5',
    label: '5J',
  },
  {
    group: 'Kind',
    groupId: '?',
    value: '5.06',
    label: '5.5J',
  },
];

const babyAges3: IAge[] = setAgesArray(6, 18, 'J', 'Kind', true);
const adult: IAge[] = setAgesArray(18, 60, 'J', 'Erwachsene', true);
const adult2: IAge[] = setAgesArray(60, 100, 'J', 'Senioren', true);

const ageData: IAge[] = [
  ...babyAges,
  ...babyAges2,
  ...babyAges3,
  ...adult,
  ...adult2,
];

export default ageData;
