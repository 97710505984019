
import {
  computed,
  defineComponent, onMounted, ref, watch,
} from 'vue';
import { Delete, Edit } from '@element-plus/icons-vue';

export default defineComponent({
  props: {
    asset: { type: Object, required: true },
    imgType: { type: String, default: 'image', validator: (prop: string) => ['image', 'stage'].includes(prop) },
    primaryId: { type: Number, default: null },
    withPrimary: { type: Boolean, default: false },
  },
  emits: ['on-delete', 'on-edit', 'update:primaryId'],
  setup(props, ctx) {
    const urlToShow = ref('');
    const computingImage = ref(true);
    const imageRef = ref();
    const correctAspectRatio = ref(true);
    // const correctAspectRatio = computed(() => {
    //   if (imageRef.value && imageRef.value.complete) {
    //     const AR = imageRef.value.naturalWidth / imageRef.value.naturalHeight;
    //     console.log(AR, props.imgType, imageRef.value.naturalWidth, imageRef.value.naturalHeight);
    //     return AR > 2.8 && AR < 2.9;
    //   }
    //   return true;
    // });

    function resizeImage(url, width, height, x, y, mime) {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const imageObj = new Image();
        imageObj.src = url;
        imageObj.crossOrigin = 'anonymous';

        canvas.width = width;
        canvas.height = height;

        imageObj.onload = () => {
          context!.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
          context!.canvas.toBlob((blob) => { resolve(blob); }, mime, 1);
        };
      });
    }

    function updatePrimaryImage(e) {
      ctx.emit('update:primaryId', e);
    }

    function convertHttpToHttps(url) {
      if (window.location.toString().includes('localhost')) return url;
      return url.includes('http://') ? url.replace(/^http:\/\//i, 'https://') : url;
    }

    async function getImgBlob(url) {
      const resp = await fetch(url);
      return resp.ok ? resp.blob() : Promise.reject(resp.status);
    }

    async function getSize(url) {
      const imgBlob = await getImgBlob(url);
      const bmp = await createImageBitmap(imgBlob);
      const { width, height } = bmp;
      bmp.close(); // free memory
      console.log('WH', width, height);
      const AR = width / height;
      correctAspectRatio.value = AR > 2.8 && AR < 2.9;
    }

    watch(() => props.asset, async () => {
      if (props.asset.meta.width === 0 && props.asset.meta.height === 0) {
        urlToShow.value = props.asset.url;
        setTimeout(() => {
          computingImage.value = false;
          getSize(urlToShow.value);
        }, 300);
      } else {
        resizeImage(
          // convertHttpToHttps(props.asset.url),
          props.asset.url,
          props.asset.meta.width,
          props.asset.meta.height,
          props.asset.meta.left,
          props.asset.meta.top,
          props.asset.mime,
        ).then((response) => {
          const urlCreator = window.URL || window.webkitURL;
          urlToShow.value = urlCreator.createObjectURL(response);
          getSize(urlToShow.value);
        });
        setTimeout(() => { computingImage.value = false; }, 300);
      }
    }, { deep: true, immediate: true });

    onMounted(async () => {
      if (props.asset.meta.width === 0 && props.asset.meta.height === 0) {
        urlToShow.value = props.asset.url;
        setTimeout(() => {
          computingImage.value = false;
        }, 300);
      } else {
        const response = await resizeImage(
          // convertHttpToHttps(props.asset.url),
          props.asset.url,
          props.asset.meta.width,
          props.asset.meta.height,
          props.asset.meta.left,
          props.asset.meta.top,
          props.asset.mime,
        );

        const urlCreator = window.URL || window.webkitURL;
        urlToShow.value = urlCreator.createObjectURL(response);

        setTimeout(() => {
          computingImage.value = false;
        }, 300);
      }
    });
    return {
      updatePrimaryImage,
      computingImage,
      urlToShow,
      Edit,
      Delete,
      imageRef,
      correctAspectRatio,
    };
  },
});
