
import { defineComponent, ref } from 'vue';
import AppIconButton from '@/components/AppIconButton.vue';
import CoursesService from '@/services/CoursesService';
import { ElLoading } from 'element-plus';

export default defineComponent({
  components: { AppIconButton },
  props: {
    item: { type: Object, required: true },
  },
  setup(props, ctx) {
    function deleteDraft() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      CoursesService.deleteDraftCourse(props.item.id).then(() => {
        ctx.emit('on-after-delete', props.item.id);
      }).finally(() => { loading.close(); });
    }
    return {
      deleteDraft,
    };
  },
});
