import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ee1ab77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "create-course-form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_form = _resolveComponent("course-form")!

  return (_ctx.isOptionsLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " data loading ... "))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_course_form, {
          "initial-data": _ctx.initialData,
          "provider-id": _ctx.providerId,
          "provider-name": _ctx.providerName,
          "course-name": _ctx.courseName,
          "location-options": _ctx.locationOptions,
          "options-loading": _ctx.isOptionsLoading,
          "participant-question-options": _ctx.participantQuestionOptions,
          "faq-question-options": _ctx.faqQuestionOptions,
          onSyncParticipantQuestions: _ctx.updateParticipantQuestions,
          onSyncFaqQuestions: _ctx.updateFAQ
        }, null, 8, ["initial-data", "provider-id", "provider-name", "course-name", "location-options", "options-loading", "participant-question-options", "faq-question-options", "onSyncParticipantQuestions", "onSyncFaqQuestions"])
      ]))
}