
import { useRouter, useRoute } from 'vue-router';
import { onMounted, defineComponent } from 'vue';
// eslint-disable-next-line import/no-cycle
import AuthService from '@/services/AuthServices';
import { ElLoading } from 'element-plus';
import LocalStorageService from '@/services/LocalStorageService';
import { useMainStore } from '@/store/mainStore';
// eslint-disable-next-line import/no-cycle
import CoursesService from '@/services/CoursesService';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const mainStore = useMainStore();

    onMounted(() => {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      setTimeout(() => {
        const tc = route.query?.access_token; // token
        const rt = route.query?.refresh_token; // refresh token
        const uuid = route.query?.uuid; // course uuid

        AuthService.loginByToken(tc as string, rt as string).then((response) => {
          mainStore.userData = response.provider;
          mainStore.roles = response.roles;
          if (uuid) {
            return CoursesService.getCourseDataByProviderByUUID(uuid as string);
            // return CoursesService.getCoursesByProvider();
          }
          return router.push('/provider-course-new');
        }).then((res) => {
          // console.log(res);
          if (res) {
            // get correct id and redirect to edit
            router.push(`/provider-course-edit/${res.id}`);
          }
        })
          .finally(() => loading.close());
      }, 1000);
    });

    return {};
  },
});
