
import {
  computed, defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import { IProviderData, IProviderProfile } from '@/types/interfaces';
import { UploadFilled } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import ProviderService from '@/services/ProviderService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ElNotification } from 'element-plus';
import useVuelidate from '@vuelidate/core';
import { required, email, numeric } from '@vuelidate/validators';
import AppFormGroupItem from '@/components/AppFormGroupItem.vue';
import ProfileFormBlock from './ProfileFormBlock.vue';

export default defineComponent({
  components: { ProfileFormBlock, AppFormGroupItem, UploadFilled },
  props: {
    profileData: { type: Object as () => IProviderProfile, default: {} as IProviderProfile },
    newProfile: { type: Boolean, default: false },
  },
  setup(props) {
    const dataLoading = ref(false);
    const editor = computed(() => ClassicEditor);
    const editorConfig = {};

    const router = useRouter();

    const sameBilling = ref(false);

    const providerProfile = reactive({
      name: '',
      invoice_address: {
        city: '',
        company: '',
        country: '',
        email: '',
        first_name: '',
        last_name: '',
        number: '',
        phone_number: '',
        postal_code: '',
        salutation: '',
        state: '',
        street: '',
      },
      office_address: {
        city: '',
        company: '',
        country: '',
        email: '',
        first_name: '',
        last_name: '',
        number: '',
        phone_number: '',
        postal_code: '',
        salutation: '',
        state: '',
        street: '',
      },
      about_us: '',
      commission: null,
      imprint: '',
      philosophy: '',
      philosophy_quote: '',
      milestones: [
        { headline: '', text: '' },
      ],
    } as IProviderData);

    // form validation rules
    const rules = {
      name: { required },
      invoice_address: {
        city: { required },
        company: { required },
        country: { required },
        email: { required, email },
        first_name: { required },
        last_name: { required },
        number: { required },
        phone_number: { required },
        postal_code: { required, numeric },
        state: { required },
        street: { required },
      },
      office_address: {
        city: { required },
        company: { required },
        country: { required },
        email: { required, email },
        first_name: { required },
        last_name: { required },
        number: { required },
        phone_number: { required },
        postal_code: { required, numeric },
        state: { required },
        street: { required },
      },
    };
    const v$ = useVuelidate(rules, providerProfile, { $lazy: true });

    const providerName = computed(() => {
      return props.profileData?.name || `Unnamed provider id: [${props.profileData?.id}]`;
    });

    function profileMilestoneChecker() {
      if (props.profileData.milestones === null || !props.profileData.milestones) {
        providerProfile.milestones = [{ headline: '', text: '' }];
      } else if (!props.profileData.milestones.length) {
        providerProfile.milestones.push({ headline: '', text: '' });
      }
    }

    function updateExistingProfile() {
      ProviderService.updateProfile(props.profileData.id as unknown as string, providerProfile).then((response) => {
        ElNotification({
          title: 'Success',
          message: 'Profile updated',
          type: 'success',
        });
        dataLoading.value = false;
      }).catch((err) => {
        if (err) {
          ElNotification({
            title: 'Error',
            message: 'Something unexpected happen!',
            type: 'error',
          });
        }
      }).finally(() => { dataLoading.value = false; });
    }

    function createNewProfile() {
      ProviderService.createProfile(providerProfile).then((response) => {
        if (response.status === 201) {
          router.push(`/providers/${response.data.id}`);
        }
      }).finally(() => { dataLoading.value = true; });
    }

    async function saveProfile() {
      if (sameBilling.value) {
        providerProfile.invoice_address = { ...providerProfile.office_address };
      }
      const result = await v$.value.$validate();
      if (!result) return;

      dataLoading.value = true;
      if (props.newProfile) {
        createNewProfile();
      } else {
        updateExistingProfile();
      }
    }

    onBeforeMount(() => {
      const forbiddenKeys = ['id', 'uuid', 'created_at', 'updated_at', 'state', 'organization_unit'];
      const formEntries = Object.fromEntries(
        Object.entries(props.profileData).filter((el) => !forbiddenKeys.includes(el[0])),
      );

      providerProfile.name = props.profileData?.name || '';
      providerProfile.commission = props.profileData?.commission || null;
      providerProfile.invoice_address = { ...props.profileData?.invoice_address };
      providerProfile.office_address = { ...props.profileData?.office_address };
      providerProfile.about_us = props.profileData?.about_us;
      providerProfile.imprint = props.profileData?.imprint;
      providerProfile.philosophy = props.profileData?.philosophy;
      providerProfile.philosophy_quote = props.profileData?.philosophy_quote;

      profileMilestoneChecker();
    });
    return {
      dataLoading,
      providerName,
      providerProfile,
      editor,
      editorConfig,
      ClassicEditor,
      sameBilling,
      saveProfile,
      v$,
    };
  },
});
