import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4631df6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "image-item__controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_ctx.computingImage)
    ? (_openBlock(), _createBlock(_component_el_skeleton, {
        key: 0,
        class: _normalizeClass([`image-item--${_ctx.imgType}`]),
        loading: _ctx.computingImage,
        animated: ""
      }, {
        template: _withCtx(() => [
          _createVNode(_component_el_skeleton_item, {
            variant: "image",
            class: _normalizeClass([`image-item--${_ctx.imgType}`]),
            style: {"width":"100%","height":"100%"}
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["class", "loading"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["image-item", [`image-item--${_ctx.imgType}`]])
      }, [
        _createElementVNode("img", {
          ref: "imageRef",
          crossorigin: "anonymous",
          src: _ctx.urlToShow,
          alt: ""
        }, null, 8, _hoisted_1),
        (_ctx.withPrimary)
          ? (_openBlock(), _createBlock(_component_el_radio, {
              key: 0,
              "model-value": _ctx.primaryId,
              label: _ctx.asset.id,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:primaryId', _ctx.asset.id))),
              class: "primary-selector",
              size: "small",
              border: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('class.button.select_primary')), 1)
              ]),
              _: 1
            }, 8, ["model-value", "label"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-delete'))),
            icon: "Delete",
            size: "small",
            circle: ""
          }),
          _createVNode(_component_el_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-edit'))),
            icon: "Edit",
            size: "small",
            circle: ""
          })
        ]),
        (!_ctx.correctAspectRatio && _ctx.imgType === 'stage')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-edit'))),
              class: "ar-warning"
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Warning)
                ]),
                _: 1
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('class.wrong_aspect_ratio')), 1)
            ]))
          : _createCommentVNode("", true)
      ], 2))
}