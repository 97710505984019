
import { defineComponent, computed, onBeforeMount } from 'vue';
// import { useMainStore } from '@/store/mainStore';
// import { useRouter } from 'vue-router';
import CoursesList from '@/modules/Courses/components/CoursesList.vue';

export default defineComponent({
  props: ['page'],
  components: {
    CoursesList,
  },
  setup() {
    // const mainStore = useMainStore();
    // const router = useRouter();
    // const isProvider = computed(() => mainStore.roles.includes('ROLE_PROVIDER'));
    // onBeforeMount(() => {
    //   if (isProvider.value) {
    //     router.push('/provider-courses');
    //   }
    // });
  },
});
