<template>
  <span class="loader"></span>
</template>

<script>
export default {};
</script>

<style scoped>
/*.loader {*/
/*  width: 48px;*/
/*  height: 48px;*/
/*  border-radius: 50%;*/
/*  position: relative;*/
/*  animation: rotate 1s linear infinite*/
/*}*/
/*.loader::before, .loader::after {*/
/*  content: "";*/
/*  box-sizing: border-box;*/
/*  position: absolute;*/
/*  inset: 0px;*/
/*  border-radius: 50%;*/
/*  border: 5px solid #FFF;*/
/*  animation: prixClipFix 2s linear infinite ;*/
/*}*/
/*.loader::after{*/
/*  border-color: #FF3D00;*/
/*  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;*/
/*  inset: 6px;*/
/*}*/

/*@keyframes rotate {*/
/*  0%   {transform: rotate(0deg)}*/
/*  100%   {transform: rotate(360deg)}*/
/*}*/

/*@keyframes prixClipFix {*/
/*  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}*/
/*  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}*/
/*  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}*/
/*  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}*/
/*  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}*/
/*}*/
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #b1b3b8;
}
.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #FF3D00;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}
</style>
