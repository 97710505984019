
import { useMainStore } from '@/store/mainStore';
import { defineComponent } from 'vue';
import type { TabsPaneContext } from 'element-plus';

export default defineComponent({
  props: {
    lockButtons: { type: Boolean, default: false },
    formStep: { type: Number, required: true },
    isPimcore: { type: Boolean, default: false },
  },
  emits: ['to-step'],
  setup(props, context) {
    const mainStore = useMainStore();

    function setStep(tab: TabsPaneContext): void {
      context.emit('to-step', tab.props.name);
    }
    return {
      mainStore,
      setStep,
    };
  },
});
