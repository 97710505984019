import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cd08d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-error"]
const _hoisted_2 = { class: "app-form-control__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-form-control", { 'has-error': _ctx.errorMessage.length }]),
    "data-error": _ctx.errorMessage
  }, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.label.length)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([{ 'app-form-control__label--required': _ctx.isRequired }, "app-form-control__label"])
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 10, _hoisted_1))
}