
import { defineComponent, onMounted, computed } from 'vue';
import { useMainStore } from '@/store/mainStore';
import { useCookies } from '@vueuse/integrations/useCookies';

export default defineComponent({
  setup() {
    const mainStore = useMainStore();
    const cookies = useCookies(['menuCookie']);

    const backToProviderAdminUri = computed(() => process.env.VUE_APP_NEXTDEER_PROVIDER_ADMIN_URI);

    function getInitialMenuState() {
      const m = cookies.get('menuCookie');
      if (!m) {
        cookies.set('menuCookie', 'open');
      }
      mainStore.menuState = cookies.get('menuCookie');
    }

    const showMenu = computed(() => mainStore.roles.includes('ROLE_USER'));
    const isProvider = computed(() => mainStore.roles.includes('ROLE_PROVIDER'));
    const isAdmin = computed(() => mainStore.roles.includes('ROLE_ADMIN'));

    onMounted(() => {
      getInitialMenuState();
    });

    return {
      mainStore,
      isProvider,
      isAdmin,
      showMenu,
      backToProviderAdminUri,
    };
  },
});
