
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import ProviderService from '@/services/ProviderService';
import { CopyDocument } from '@element-plus/icons-vue';
import { useRouter } from 'vue-router';
import CoursesService from '@/services/CoursesService';

export default defineComponent({
  components: { CopyDocument },
  props: {
    providerId: { type: Number, required: true },
  },
  setup(props, ctx) {
    const dataLoading = ref(true);
    const coursesList = ref([]);
    const fullscreenLoading = ref(false);
    const router = useRouter();

    const courseListSorted = computed(() => {
      const draft = coursesList.value.filter((el) => (el as any).state === 'draft');
      const active = coursesList.value.filter((el) => (el as any).state === 'active');
      const inactive = coursesList.value.filter((el) => (el as any).state === 'inactive');
      const archived = coursesList.value.filter((el) => (el as any).state === 'archived');
      return [...draft, ...active, ...inactive, ...archived];
    });

    function copyCourse(id) {
      fullscreenLoading.value = true;
      CoursesService.copyCourse(id).then((response) => {
        router.push(`/course/${response.id}/edit`);
      }).finally(() => { fullscreenLoading.value = false; });
      // setTimeout(() => {
      //   fullscreenLoading.value = false;
      //   router.push(`/course/${id}/edit`);
      // }, 3000);
    }

    onMounted(() => {
      ProviderService.getCoursesByProviderId(props.providerId).then((r) => {
        // console.log(r);
        coursesList.value = r.data;
        dataLoading.value = false;
      });
    });
    return {
      dataLoading,
      coursesList,
      courseListSorted,
      copyCourse,
      fullscreenLoading,
    };
  },
});
