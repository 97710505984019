import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "profile-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_form = _resolveComponent("profile-form")!

  return (_ctx.dataLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " loading ... "))
    : (_openBlock(), _createBlock(_component_profile_form, {
        key: 1,
        "profile-data": _ctx.profileData
      }, null, 8, ["profile-data"]))
}