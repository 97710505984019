import { defineStore } from 'pinia';
import { IAsset } from '@/types/interfaces';

export interface State {
  initLoad: boolean;
  showUpdatingOverlay: boolean;
  providerId: null|number;
  courseId: null|number;
  assetsList: IAsset[];
  faqOptions: any[];
  categoryOptions: any[];
  levelOptions: any[];
  targets: any[];
  rhythmOptions: any[];
  highlightOptions: any[];
}

export const useCourseManageStore = defineStore({
  id: 'courseManageStore',

  state: (): State => ({
    initLoad: true,
    showUpdatingOverlay: false,
    providerId: null,
    courseId: null,
    assetsList: [],
    faqOptions: [],
    // dictionary
    categoryOptions: [],
    levelOptions: [],
    targets: [],
    rhythmOptions: [],
    highlightOptions: [],
  }),

  getters: {
    pdfList: (state) => state.assetsList.filter((el: IAsset) => el?.mime.includes('pdf')),
    targetsNormalized: (state) => {
      const tf = state.targets.filter((el: any) => el.visible);
      return tf.map((el: any) => ({ ...el, value: el.id, label: el.name }));
    },
  },

  actions: {
    handleAssetRemove(assetId) {
      const idx = this.assetsList.findIndex((el) => el.id === assetId);
      if (idx > -1) {
        this.assetsList.splice(idx, 1);
      }
    },
  },
});
