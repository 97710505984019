
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    lockButtons: { type: Boolean, default: false },
    formStep: { type: Number },
    isDraft: { type: Boolean, default: true },
    isPimcore: { type: Boolean, default: false },
  },
  emits: ['on-back', 'on-next', 'on-draft-save', 'on-publish'],
});

