import { defineStore } from 'pinia';

export interface IUser {
  id: number;
  name: string;
  uuid: string;
}
export type RootState = {
  user: string;
  userStatus: number;
  menuState: string;
  messages: Map<string, string>;
  locale: string;
  roles: string[];
  userData: null | IUser;
}

export const useMainStore = defineStore({
  id: 'mainStore',

  state: () => ({
    user: '',
    userStatus: 0,
    messages: new Map<string, string>(),
    menuState: '',
    locale: 'en',
    roles: [],
    userData: null,
  } as RootState),

  getters: {
    isMenuOpen: (state) => state.menuState === 'open' || false,
    // eslint-disable-next-line no-nested-ternary
    userType: (state) => (state?.roles?.includes('ROLE_PROVIDER') ? 2 : state?.roles?.includes('ROLE_ADMIN') ? 3 : 0),
  },

  actions: {
  },
});
