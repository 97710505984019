// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/Ubuntu/Ubuntu-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/Ubuntu/Ubuntu-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/Ubuntu/Ubuntu-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/Ubuntu/Ubuntu-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ":root{--el-border-color:#585857;--app-max-width:1360px}@font-face{font-family:Ubuntu;font-style:normal;font-weight:400;src:local(\"Ubuntu-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:Ubuntu;font-style:normal;font-weight:300;src:local(\"Ubuntu-Light\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-family:Ubuntu;font-style:normal;font-weight:500;src:local(\"Ubuntu-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}@font-face{font-family:Ubuntu;font-style:normal;font-weight:500;src:local(\"Ubuntu-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\")}#app{font-family:Ubuntu,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#2c3e50}.app-layout{display:flex;flex-direction:column;min-height:100vh;overflow:hidden}.app-main{max-width:var(--app-max-width);width:100%;margin:0 auto}.app-main,.main{flex-grow:1;display:flex}.main{padding:20px;flex-direction:column}.el-form-item__label{font-size:12px!important;line-height:16px!important;margin-bottom:4px!important;text-transform:uppercase}.fade-enter-active,.fade-leave-active{transition:opacity .2s ease-out}.fade-enter-from,.fade-leave-to{opacity:0}.el-select-dropdown{font-family:Ubuntu,sans-serif}.el-popper{text-transform:none}.app-form-group .el-input__wrapper,.app-form-group .el-textarea__inner,.el-select .el-input__wrapper,.profile-page .el-input__wrapper,.profile-page .el-textarea__inner{box-shadow:inset 0 0 0 1px #909399}.el-checkbox--large{height:24px!important}.el-checkbox--large .el-checkbox__inner{border-color:#666}", ""]);
// Exports
module.exports = exports;
