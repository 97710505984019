import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_info_tooltip = _resolveComponent("app-info-tooltip")!
  const _component_pdf_input = _resolveComponent("pdf-input")!
  const _component_app_form_group = _resolveComponent("app-form-group")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_asset_delete_dialog = _resolveComponent("asset-delete-dialog")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 24 }, {
        default: _withCtx(() => [
          _createVNode(_component_app_form_group, {
            label: _ctx.$t('class.terms'),
            errors: [...(_ctx.vld?.terms?.$errors || []), ...(_ctx.externalErrors.terms || [])],
            simplified: "",
            required: "",
            name: "terms"
          }, {
            helper: _withCtx(() => [
              _createVNode(_component_app_info_tooltip, {
                content: _ctx.$t('class.info_sign.class_terms')
              }, null, 8, ["content"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_pdf_input, {
                "label-name": _ctx.$t('class.terms'),
                source: _ctx.terms,
                "course-id": _ctx.courseId,
                disabled: _ctx.viewOnly,
                onUpdateOnUpload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update-on-upload', $event))),
                onDeletePdf: _ctx.deletePdf,
                target: "terms"
              }, null, 8, ["label-name", "source", "course-id", "disabled", "onDeletePdf"])
            ]),
            _: 1
          }, 8, ["label", "errors"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 24 }, {
        default: _withCtx(() => [
          _createVNode(_component_app_form_group, {
            label: _ctx.$t('class.claims'),
            errors: [...(_ctx.vld?.claims?.$errors || []), ...(_ctx.externalErrors?.claims || [])],
            simplified: "",
            name: "claims"
          }, {
            helper: _withCtx(() => [
              _createVNode(_component_app_info_tooltip, {
                content: _ctx.$t('class.info_sign.class_claims')
              }, null, 8, ["content"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_pdf_input, {
                "label-name": _ctx.$t('class.claims'),
                source: _ctx.claims,
                "course-id": _ctx.courseId,
                disabled: _ctx.viewOnly,
                onUpdateOnUpload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update-on-upload', $event))),
                onDeletePdf: _ctx.deletePdf,
                target: "claims"
              }, null, 8, ["label-name", "source", "course-id", "disabled", "onDeletePdf"])
            ]),
            _: 1
          }, 8, ["label", "errors"])
        ]),
        _: 1
      }),
      _createVNode(_component_asset_delete_dialog, {
        "delete-dialog": _ctx.deleteDialog,
        "asset-id": _ctx.assetIdToDelete,
        onClose: _cache[2] || (_cache[2] = ($event: any) => {_ctx.deleteDialog = false; _ctx.assetIdToDelete = null}),
        onOnCancel: _cache[3] || (_cache[3] = ($event: any) => {_ctx.deleteDialog = false; _ctx.assetIdToDelete = null}),
        onHandleAfterDelete: _ctx.handleAfterDelete
      }, null, 8, ["delete-dialog", "asset-id", "onHandleAfterDelete"])
    ]),
    _: 1
  }))
}