
import { Cropper, CropperResult } from 'vue-advanced-cropper';
import { defineComponent, ref, computed } from 'vue';
// import { IImageCrop } from '@/types/interfaces';

import 'vue-advanced-cropper/dist/style.css';
import CoursesService from '@/services/CoursesService';

export default defineComponent({
  components: { Cropper },
  props: {
    // image: { type: Object as () => IImageCrop, required: true },
    image: { type: String, required: true },
    meta: { type: Object as () => unknown|null, default: null },
    // imageType: { type: String, required: true },
    imageName: { type: String, required: true },
    height: { type: String, default: '300' },
    cropperType: { type: String, default: 'stage' },
    courseId: { type: Number, default: null },
    assetId: { type: Number, default: undefined },
  },
  setup(props, context) {
    const cropperRef = ref();
    const dataUpdating = ref(false);

    const defaultPosition = computed(() => {
      return props.meta ? { left: (props?.meta as any).left, top: (props.meta as any).top } : null;
    });

    const defaultSize = computed(() => {
      const defStencil = { width: 300, height: 200 };
      if (cropperRef.value) {
        defStencil.width = Math.floor(cropperRef.value?.image.width / 2);
        defStencil.height = Math.floor(cropperRef.value?.image.height / 2);
      }
      return props.meta ? { width: (props?.meta as any).width || defStencil.width, height: (props.meta as any).height || defStencil.height } : null;
    });

    const cropperAR = computed(() => { return props.cropperType === 'stage' ? (72 / 25) : (23 / 15); });
    /** images can be 2 types - stage and image */
    const stencilSettings = ref({
      aspectRatio: cropperAR.value,
      previewClass: 'preview',
    });

    async function uploadImage() {
      if (cropperRef.value) {
        dataUpdating.value = true;
        const { coordinates, imageTransforms, image } = cropperRef.value.getResult();

        const payload = {
          meta: JSON.stringify({
            order: 0,
            width: coordinates.width,
            height: coordinates.height,
            top: coordinates.top,
            left: coordinates.left,
            type: props.cropperType,
          }),
          course_id: props.courseId,
          file: props.image.replace('data:', '').replace(/^.+,/, ''),
          name: props.imageName,
        };

        try {
          const res = await CoursesService.uploadAsset(payload);
          if (res.status === 201 || res.status === 200) {
            context.emit('on-success', { data: res.data, type: props.cropperType });
          }
        } catch (e) {
          dataUpdating.value = false;
        }
        dataUpdating.value = false;
      }
    }

    async function updateImage() {
      if (cropperRef.value) {
        dataUpdating.value = true;
        const { coordinates, imageTransforms, image } = cropperRef.value.getResult();

        const payload = {
          meta: JSON.stringify({
            order: 0,
            width: coordinates.width,
            height: coordinates.height,
            top: coordinates.top,
            left: coordinates.left,
            type: props.cropperType,
          }),
          course_id: props.courseId,
          name: props.imageName,
        };
        const res = await CoursesService.updateAsset(payload, props.assetId);
        // const res2 = await CoursesService.getCourseAssets();
        dataUpdating.value = false;
        context.emit('on-success', { data: res, type: props.cropperType });
      }
    }

    async function manageImage() {
      if (props.meta !== null && props.assetId !== undefined) {
        await updateImage();
      } else {
        await uploadImage();
      }
    }
    return {
      dataUpdating,
      cropperRef,
      stencilSettings,
      manageImage,
      defaultPosition,
      defaultSize,
    };
  },
});
